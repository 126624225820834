import { store } from "../store";
import { setFollowedArtistDetails, setUgcData } from "../store/slices/common-slice";
import { staticUgcContent } from "../utils/constants";
import { getUGCContent, getUGCUserDetailByAssetIds, getUgcUploadOwnerData } from "./ugc-services";
import gluedin from "gluedin";
const userModule = new gluedin.GluedInUserModule();
const feedModule = new gluedin.GluedInFeedModule();
const authModule = new gluedin.GluedInAuthModule();
export const fetchUgcContent = async (
  isLoggedIn,
  dispatch,
  toggleLoader,
  payload
) => {
  try {
    console.log(isLoggedIn, "isLoggedIn fetchUgcContent")
    let ugcVideos = [];
    if (isLoggedIn) {
      toggleLoader(true);
      let likedVideos;
      const data = await feedModule.searchVideo(payload);
      console.log(data, "data searchVideo")
      const isSearchResultFound = data && data?.data?.result;
      dispatch(setUgcData([]));
      if (isSearchResultFound?.length) {
        const userIds = isSearchResultFound.map((vid) => vid.videoId);
        console.log(userIds, "videoIDsvideoIDs")
        if (userIds?.length) {
          let likeVideosResponse = await feedModule.Like(userIds);
          console.log(likeVideosResponse, "likeVideosResponse", likeVideosResponse.data.result)
          if (
            likeVideosResponse.status === 200 ||
            likeVideosResponse.status === 201
          ) {
            likedVideos = likeVideosResponse.data.result;
          }
          console.log(likedVideos, "likedVideos")
          const res = await getUGCContent(userIds);
          const ugcContent = res?.data?.data;
          console.log(ugcContent, "checking", isSearchResultFound)
          if (ugcContent) {
            ugcContent?.map((urlVid) => {
              isSearchResultFound?.map((vid) => {
                if (vid.videoId.toLowerCase() === urlVid.assestId.toLowerCase()) {
                  vid["contentUrl"] = urlVid.contentUrl;
                  console.log("addVideoUrl", vid);
                  ugcVideos.push(vid);
                }
              });
            });
            console.log(ugcVideos, "ugcVideos")
            const combinedResponse = { itemList: ugcVideos, likedVideos: likedVideos };
            console.log(combinedResponse, "combinedResponsecombinedResponse")
            dispatch(setUgcData(combinedResponse));
          } else {
            dispatch(setUgcData([]));
            throw new Error("Invalid response structure from getUGCContent");
          }
        } else {
          throw new Error("No video IDs found");
        }
      } else {
        throw new Error("No results found or invalid data structure from feedModule.searchVideo");
      }
    } else if (!isLoggedIn && ugcVideos && ugcVideos?.length) {
      console.log(ugcVideos, "ugcVideos")
      dispatch(setUgcData(staticUgcContent));
    }
  } catch (error) {
    console.error("Error fetching UGC content:", error);
  } finally {
    toggleLoader(false);
  }
};

export const followArtist = async (artistId, followState) => {
  var formData = {
    followingId: artistId,
    isFollow: followState,
  };
  const userModuleResponse = await userModule.followUser(formData);
  console.log(userModuleResponse, "userModuleResponse followArtist")
  if (userModuleResponse && userModuleResponse?.status === 201 || userModuleResponse?.status === 200) {
    return userModuleResponse?.data?.status;
  } else {
    return false;
  }
};

export const getCreatorLeaderBoard = async (toggleLoader = () => { }, page = 1, pageSize = 10,
  setCreateorLeaderBoardList = () => { },
  setTotalCount = () => { }
) => {
  try {
    toggleLoader(true)
    let updatedArray = []
    const feedModule = new gluedin.GluedInFeedModule();
    const creatorLeaderBoardResponse = await feedModule.getCreatorLeaderBoard({
      limit: 10,
      offset: page
    });
    console.log(creatorLeaderBoardResponse, "creatorLeaderBoardResponse")
    if (creatorLeaderBoardResponse) {
      const data = creatorLeaderBoardResponse?.data?.result
      const userIds = data && data.map((vid) => vid?.userId.split('_')[0]);
      console.log(userIds, "userIds data data data", data)
      if (userIds?.length) {

        const res = await getUgcUploadOwnerData(userIds);
        console.log(res, "resres getUGCUserDetailByAssetIds")
        const ugcUploadOwnerData = res?.data?.data
        console.log(ugcUploadOwnerData, "ugcUploadOwnerData")
        if (ugcUploadOwnerData) {
          updatedArray = data.map((item) => {
            const matchingUser = ugcUploadOwnerData.find(
              (userItem) => userItem.userId === item.userId.split('_')[0].toLowerCase()
            );
            console.log(matchingUser, "matchingUser")
            if (matchingUser) {
              return { ...item, user: matchingUser.user };
            }
            return item;
          });
          console.log(updatedArray, "updatedArray")
        }
      }
      const totalData = creatorLeaderBoardResponse?.data?.total
      console.log(data
        , "data leaderrrr", creatorLeaderBoardResponse)
      if (data && data?.length) {
        setCreateorLeaderBoardList(updatedArray)
        setTotalCount(totalData)
      }
      console.log(data, "CreatorLeaderBoardResponse:", creatorLeaderBoardResponse);
    }
  } catch (error) {
    toggleLoader(false)
    console.error("Error fetching Creator Leader Board:", error.message || error);
    // Additional error handling logic can be added here, such as logging the error to an external service
  } finally {
    toggleLoader(false)
  }
}


export const getFollowedArtistInfo = async () => {
  const userModule = new gluedin.GluedInUserModule();
  const userConfigResponse = await userModule.getUserMetaIds();
  console.log(userConfigResponse, "userConfigResponse")
  if (
    userConfigResponse &&
    userConfigResponse?.status === 200 ||
    userConfigResponse?.status === 201
  ) {
    const following = userConfigResponse.data.result.following;
    let followingData = {};
    following?.map((el) => (followingData[el] = true));
    store.dispatch(setFollowedArtistDetails(followingData));
  }
};