import React from 'react';
import { Link } from 'react-router-dom';
import useTranslation from '../utils/hooks/use-translation';

function Page404() {

  const { t } = useTranslation();
  let content = {
    iconUrl: '/assets/img/icons/404.svg',
    title: '',
    subTitle: t.common.page_not_found,
    btnText: t.common.go_to_home_page,
  };
  return (
    <>
      <div className="xy-center block-middle">
        {content.iconUrl && (
          <img
            src={`${content.iconUrl}`}
            className={`icon ${content.iconUrl}`}
            alt=""
          />
        )}
        {content.title && <h1 className="title">{content.title}</h1>}
        {content.subTitle && <h2 className="sub-title">{content.subTitle}</h2>}

        {content.btnText && (
          <Link to="/" className="btn btn-primary mt-5">
            {content.btnText}
          </Link>
        )}
      </div>
    </>
  );
}

export default Page404;
