import React, { useState } from "react";
import AppModal from "./index";
import TimerComponent from "../common/Timer";
import useTranslation from "../../utils/hooks/use-translation";
import PointsBox from "../common/PointsBox";
import CommonFormikForm from "../../utils/form";
import {
  ReferralValidationSchema,
  referCodeValidationSchema,
  signUpValidationSchema,
} from "../../utils/validations";
import { useLocation } from "react-router";
import useLoader from "../../utils/hooks/use-loader";
import { sendReferralAPI, verifyRefCodeAPI } from "../../services";
import toast from "react-hot-toast";
import { getCDSPixelId, handleErrorMessages } from "../../utils/helpers";
import usePopup from "../../utils/hooks/use-popup";
import { referalSuccessContentType } from "../../utils/constants/popup-content";

const ReferFriendModal = ({
  show,
  type,
  getReferralList,
  setReferralCode = () => {},
  setShowThankYouModal = () => {},
  onClose = () => {},
  showThankYouModal,
}) => {
  const { openSuccessPopup } = usePopup();
  const { t } = useTranslation();
  const { state } = useLocation();
  const { toggleLoader } = useLoader();
  let referralSchema = ReferralValidationSchema(t);
  let referCodeValidation = referCodeValidationSchema(t);
  const rederReferSuccessModel = (data) => {
    let contentType = referalSuccessContentType(t, data?.code)
    openSuccessPopup(true, contentType);
  };
  const handleSubmit = async (values, { setFieldError, resetForm }) => {
    const mobileNumber = values.mobileCode.replace(/\+880/g, "");
    const reqData = {
      email: values.email,
      mobileCode: mobileNumber,
    };
    toggleLoader(true);
    if (type === "refer_code") {
      verifyRefer(values, setFieldError, resetForm);
    } else {
      sendRefer(reqData, setFieldError, resetForm);
    }
  };
  const onCloseRefModal = (data) => {
    setReferralCode(data?.data?.data);
    onClose();
    // openReferFriendPopup()
    if (data?.data?.succeeded) {
      setTimeout(() => {
        setShowThankYouModal(true);
      }, 400);
    }
  };

  console.log(showThankYouModal, "showThankYouModal");
  //send refer api call
  const sendRefer = async (values, setFieldError, resetForm) => {
    try {
      const hfSessionKey = await getCDSPixelId();
      let reqData = {
        mobileNo: values?.mobileCode,
        email: values?.email ? values?.email : "",
        hfSession: hfSessionKey || "",
      };
      const res = await sendReferralAPI(reqData);
      if (res.data && res.data.succeeded) {
        onCloseRefModal(res);
        getReferralList();
        resetForm();
        let resData = {
          code: res.data && res.data.data,
          message: res.data && res.data.message,
        };
        rederReferSuccessModel(resData);
        //GA event added for successfully refer number
        window.dataLayer.push({ event: "CSBangla24_ReferNow" });
        toast.success(t.messages.refer_success);
      } else {
        handleErrorMessages(res, setFieldError, "mobileCode");
      }
    } catch (err) {
    } finally {
      toggleLoader(false);
    }
  };

  const verifyRefer = async (values, setFieldError, resetForm) => {
    try {
      let reqData = {
        refCode: values.code,
      };
      const res = await verifyRefCodeAPI(reqData);
      if (res.data && res.data.succeeded) {
        //GA event added for successfully refer number
        window.dataLayer.push({ event: "CSBangla24_HaveInviteCode" });
        toast.success(t.messages.refer_code_success);
        resetForm();
        getReferralList();
        onCloseRefModal();
      } else {
        let message = res.data && res.data.message;
        setFieldError("code", message);
      }
    } catch (err) {
    } finally {
      toggleLoader(false);
    }
  };

  const initialValues = {
    email: "",
    mobileCode: state && state.mobile ? state.mobile : "",
  };

  const initialValues2 = {
    code: "",
  };

  const renderInputs = [
    {
      type: "text",
      name: "mobileCode",
      placeHolder: "Enter your friend's mobile no.",
      inputType: "number",
    },
    {
      type: "email",
      name: "email",
      placeHolder: "Enter your friend's email (optional)",
    },
  ];

  const renderInput2 = [
    {
      type: "text",
      name: "code",
      placeHolder: "XXXXXX",
      inputType: "text",
      label: t.common.enter_referal_code,
    },
  ];
  const closeThankyouModal = () => {
    setShowThankYouModal(false);
  };

  return (
    <>
      {type === "refer_code" ? (
        <AppModal
          show={show}
          onClose={onCloseRefModal}
          bodyClass="px-5 py-9  modal-unique-code"
        >
          <CommonFormikForm
            initialValues={initialValues2}
            onSubmit={handleSubmit}
            renderInputs={renderInput2}
            ButtonText="Submit"
            validationSchema={referCodeValidation}
          />
        </AppModal>
      ) : type === "refer_number" ? (
        <AppModal
          show={show}
          onClose={onCloseRefModal}
          bodyClass="px-4 px-md-0 pt-0 pb-md-5 pb-3 pb-md-10 modal-body-success modal-body-refer-friend"
          size="md"
        >
          <div className="form-invert">
            <h6 className="modal-heading pb-md-3 pb-2">
              {t.model_messages.get_your_friends_grooving}
            </h6>
            <div className="mb-4 mb-md-6">
              <p className="modal-description pb-md-0 pb-0 mb-0 pr-2 d-inline-block">
                {t.model_messages.invite_friends_to_join}{" "}
                <div className=" d-inline-block position-relative">
                  {" "}
                  <PointsBox coin={100}/>{" "}
                </div>
                <span className="modal-description pb-md-0 pb-0 mb-0">
                  {" "}
                  {t.model_messages.on_each_referral}
                </span>
              </p>
            </div>
            <CommonFormikForm
              initialValues={initialValues}
              validationSchema={referralSchema}
              onSubmit={handleSubmit}
              renderInputs={renderInputs}
              ButtonText={t.model_messages.refer_now}
              errorClass={"position-relative mb-n5"}
            />
          </div>
        </AppModal>
      ) : (
        <CommonFormikForm />
      )}
    </>
  );
};

export default ReferFriendModal;
