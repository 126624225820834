import React from "react";
import { Table, Button } from "react-bootstrap";
import useTranslation from "../../utils/hooks/use-translation";

const CommonTable = (props) => {
  const { t } = useTranslation();
  const {
    header,
    tableTitle,
    rows,
    columns,
    tableClassName,
    columnName,
    totalCount,
    setPage,
    page,
    pageSize,
    showPagination = true,
  } = props;
  const handlePrevious = () => {
    if (page > 1) {
      setPage((prevPage) => prevPage - 1);
    }
  };

  const handleNext = () => {
    const maxPage = Math.ceil(totalCount / pageSize);
    console.log(page < maxPage, "page < maxPage");
    if (page < maxPage) {
      setPage(page + 1);
    }
  };
  const renderTableData = () => {
    return (
      rows &&
      rows?.length > 0 &&
      rows.map((row, rowIndex) => {
        let statusClass =
          row.status === t.common.pending
            ? "pending"
            : row.status === t.common.active
              ? "accept"
              : row.status === "Send Again"
                ? "pending"
                : "";

        let isAction = row.isAction && row?.isAction;
        let isClassRequired = row?.className && row?.className;
        let isColumnSpecified = row?.classCloumn && row?.classCloumn;
        return (
          <tr key={rowIndex} className={statusClass}>
            {columnName.map((column, cellIndex) => (
              <td key={cellIndex}>
                <span
                  className={
                    isClassRequired && isColumnSpecified === cellIndex
                      ? isClassRequired
                      : ""
                  }
                  onClick={() => {
                    if (isAction) {
                      isAction(row);
                    }
                  }}
                >
                  {row[column] != undefined ? row[column] : "N/A"}
                </span>
              </td>
            ))}
          </tr>
        );
      })
    );
  };

  return (
    columns &&
    Array?.isArray(columns) &&
    columns?.length && (
      <>
        {tableTitle && <h2>{tableTitle}</h2>}
        <Table striped bordered hover responsive className={tableClassName}>
          {header && (
            <thead>
              <tr>
                {columns &&
                  columns?.map((column, index) => (
                    <th key={index}>{column}</th>
                  ))}
              </tr>
            </thead>
          )}
          {rows.length > 0 && <tbody>{renderTableData()}</tbody>}
        </Table>
        {rows === 0 && (
          <div style={{ height: "100px" }}>
            <h2 className="text-center mt-14">{t.common.no_data_found}</h2>
          </div>
        )}
        {showPagination && rows.length > 0 && (
          <div className="d-flex justify-content-center align-items-center pb-5 pagination-wrapper">
            <Button
              variant="primary"
              size=""
              onClick={handlePrevious}
              className="btn btn-primary py-3 px-9 mr-5"
              disabled={page === 1}
            >
              {t.common.previous}
            </Button>
            <Button
              variant="primary"
              size=""
              onClick={handleNext}
              disabled={page === Math.ceil(totalCount / pageSize)}
              className="btn btn-primary py-3 px-9"
            >
              {t.common.next}
            </Button>
          </div>
          // New Pagination code
          //                   <div className="d-flex justify-content-center my-5 my-md-8 pagination-container">
          //             <div className="mr-3">First</div>
          //             <img
          //               src="/assets/img/icons/left-arrow.svg"
          //               alt="left arrow"
          //               className="mr-3"
          //             />
          //             <div className="current-page mr-3">1</div>
          //             <div className="mr-3">2</div>
          //             <img
          //               src="/assets/img/icons/right-arrow.svg"
          //               alt="right arrow"
          //               className="mr-3"
          //             />
          //             <div className="mr-3">Last</div>
          //         </div>
          //         New pagination end
        )}
      </>
    )
  );
};

export default CommonTable;
