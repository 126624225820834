import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import NavLinks from "./nav-links";
import useAuth from "../../utils/hooks/use-auth";
import { logoutUser } from "../../store/slices/auth-slice";
import { useDispatch, useSelector } from "react-redux";
import { menuData, menuData2 } from "../../utils/constants";
import useTranslation from "../../utils/hooks/use-translation";
import { gluedinLogout } from "../../services";
import { setAfterLoginAction } from "../../store/slices/common-slice";
import { base64ToImageSrc } from "../../utils/helpers";
import ShareBadgePopup from "../common/appForm/ShareBadgePopup";
import Stepper from "../common/Stepper";

const Sidebar = (props) => {
  const { t } = useTranslation();
  let allMenuOptions = menuData(t);
  let menuDataOptions = menuData2(t);
  const [openShareBadgePopup, setOpenShareBadgePopup] = useState(false);
  const { isLoggedIn, userDetails } = useAuth();
  const { navigateTo } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const homePageData = useSelector((state) => state?.common?.homepageDetails);
  const totalLoginCount =
    userDetails && userDetails?.loginCount ? userDetails?.loginCount : 0;
  const maxLoginCount = Math.min(totalLoginCount, 3); // Ensure steps don't exceed 3
  const logoutUserAction = () => {
    localStorage.clear();
    dispatch(logoutUser());
    dispatch(
      setAfterLoginAction({
        action: "",
        redirectPath: "/",
      })
    );
    gluedinLogout();
    navigate("/");
  };

  const redirectToLogin = () => {
    setAfterLoginAction({ action: "", redirectPath: "/" });
    navigateTo();
    navigate("/login");
  };

  const redirectToMyProfile = () => {
    navigateTo();
    navigate("/my_profile");
  };

  return (
    <>
      <div className="w-100">
        <Button
          variant={"grey"}
          size="lg"
          style={{ borderRadius: "1rem" }}
          className=" btn-block p-2"
          onClick={() =>
            isLoggedIn ? redirectToMyProfile() : redirectToLogin()
          }
        >
          {isLoggedIn ? (
            <>
              <div className="btn-icon">
                <div className="user-card">
                  <img
                    className="user-pic"
                    src={
                      userDetails && userDetails.avatarURL
                        ? userDetails.avatarURL
                        : userDetails && userDetails.profilePicture
                        ? base64ToImageSrc(
                            userDetails.profilePicture,
                            "image/jpeg"
                          ) // Adjust the content type as per your image type
                        : "/assets/img/icons/default-user.svg"
                    }
                    alt=""
                  />
                  <div className="user-details">
                    <h6 className="user-title">
                      {userDetails && userDetails.name}
                    </h6>
                    <div className="user-coin d-flex align-items-center">
                      <img
                        className="mr-1"
                        width={14}
                        src="/assets/img/icons/Layer 1.svg"
                        alt=""
                      />
                      <span className=" font-weight-light">
                        {homePageData && homePageData.coinCount}
                      </span>
                    </div>
                  </div>
                </div>
                <span className="icon icon-right-arrow icon-Right-arrow-01"></span>
              </div>
              <p className="user-coin text-left mt-1">
                {t.profile.rank} :
                <span className=" font-weight-bold ml-1">
                  {userDetails && userDetails?.ranking
                    ? userDetails?.ranking
                    : 0}
                </span>
                {userDetails?.badgeurl?.imageUrl ? (
                  <img
                    src={userDetails?.badgeurl?.imageUrl}
                    className="fan-badges"
                    onClick={() => setOpenShareBadgePopup(true)}
                    alt={"badges"}
                  />
                ) : (
                  <></>
                )}
              </p>

              <p
                className="d-flex d-md-none justify-content-between mb-0"
                style={{ fontSize: "12px" }}
              >
                <span>Weekly Logins</span>
                <span>
                  <span style={{ opacity: 0.5 }}>{maxLoginCount}</span>/3{" "}
                </span>
              </p>
              {/* <Stepper /> */}
            </>
          ) : (
            <>
              <div className="btn-icon py-1">
                <span>{t.header.login}</span>
                <span className="icon icon-right-arrow icon-Right-arrow-01"></span>
              </div>
            </>
          )}
        </Button>
        <NavLinks data={allMenuOptions} navigateTo={navigateTo} />
      </div>
      {openShareBadgePopup && (
        <ShareBadgePopup
          show={openShareBadgePopup}
          onClose={() => setOpenShareBadgePopup(false)}
          type={"share-badge"}
        />
      )}
      <div className="w-100">
        <NavLinks data={menuDataOptions} navigateTo={navigateTo} />
        {isLoggedIn && (
          <Link
            to=""
            className="btn-link btn-link-lg d-inline-block mt-5"
            onClick={() => logoutUserAction()}
          >
            {t.header.logout}
          </Link>
        )}
      </div>
    </>
  );
};

export default Sidebar;
