export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const version = "v1";
export const IMAGE_BASE_URL =
  "https://017521-content-dev.s3.ap-south-1.amazonaws.com";
//export const GOOGLE_CLIENT_ID = '876198659295-fhs3bm3p842q6ltngi79k0c409r6m529.apps.googleusercontent.com'
//export const FACEBOOK_APP_ID = '930514504584520'
export const LANGUAGEID = 1;

export const GOOGLE_CLIENT_ID =
  "363085826688-2injlogrkd72rjfnng30nqc1ojpru3ih.apps.googleusercontent.com";
export const FACEBOOK_APP_ID = "930514504584520";

// take rail ids from env 
export const RailId = {
  hero_banner: process.env.REACT_APP_HOME_BANNER_RAIL_ID,
  promo_banner: process.env.REACT_APP_PROMO_RAIL_ID,
  spotlight: process.env.REACT_APP_SPOTLIGHT_RAIL_ID,
  behind_the_scene: process.env.REACT_APP_BTS_RAIL_ID,
  melodies_rewind: process.env.REACT_APP_MELODIES_RAIL_ID,
  whats_new: process.env.REACT_APP_WHATS_NEW_RAIL_ID,
  artist: process.env.REACT_APP_ARTIST,
  around_the_world: process.env.REACT_APP_AROUND_THE_WORLD,
}

// dev raild ids 
// export const RailId = {
//   hero_banner: `ef234ac5-2f0a-435a-ab23-a859fd9511de`,
//   promo_banner: `7d035628-1bdf-428a-95a3-ee9e1c73ccf8`,
//   spotlight: `70a988d0-b6e9-420a-8229-9c2df3a849b5`,
//   behind_the_scene: `d82cbfb3-e3ca-460c-9bd2-2bf278ed146d`,
//   melodies_rewind: `5f8ee1dd-e957-4963-8cfd-6ebd075fc851`,
//   whats_new: `a5a1c78d-eccf-416b-becb-40ca425373e5`,

//   // prod rails 
//   // hero_banner: `9d18016c-fbf1-42d3-8df3-df0e2f6e1ed5`,
//   artist: `afd01d0e-6fd3-4312-9c91-416c779df8c0`,
//   around_the_world: `1fe45062-7fcf-4991-9f72-faf544f5e31f`,
// }

// prod rail ids  
// export const RailId = {
//   hero_banner : `9d18016c-fbf1-42d3-8df3-df0e2f6e1ed5`,
//   promo_banner:`dc6fd2b1-712d-499b-81fe-d4dbc959cd8b`,
//   whats_new:`32e88551-33c5-43bb-be8b-fee79abe2345`,
//   spotlight:`54c6c680-ea8d-4650-a892-21ee06b7559d`,
//   melodies_rewind:`f315ecdc-8113-4916-a923-d6ab22ad1182`,
//   behind_the_scene:`715a3131-678d-42c7-931c-1850eec2ae84`,
//   artist:`afd01d0e-6fd3-4312-9c91-416c779df8c0`,
//   around_the_world:`1fe45062-7fcf-4991-9f72-faf544f5e31f`
// }


export const rewards = [
  {
    totalCoinsEarned: 0,
    currentBalance: 0,
    lastRedeemedCoins: 0,
    rewards: [
      {
        rewardsId: 1,
        rewardsName: "JioSaavn Subscription",
        rewardsDescription: "Redeem by 31st October, 2024",
        rewardsLongDesc:
          "Listen to all your favorite songs by redeeming  one month premium subscription to JioSaavn.",
        noOfCoins: "40",
        coinTypeId: 1,
        coinTypeName: "General",
        quotaTypeId: 1,
        quotaTypeName: "PerWeek_JioSaavn",
        iconURL:
          "https://s3.ap-south-1.amazonaws.com/017073-cokestudio2.0-dev/rewards/Icons/jioSavan.webp",
        imageURL: "img05.webp",
        canClaim: false,
        rewardsDisplayOrder: 1,
        isIdRequired: false,
        isPassportRequired: false,
        collectNow: true,
        enterNow: false,
        claimTimer: "00:00:00",
        claimed: false,
        watchVideo: false,
        startQuiz: false,
        displayMessage: "",
        isPromocode: true,
        type: "Bharat",
        promoCode: null,
        isRewardUnlocked: false,
        quotaOver: false,
      },
      {
        rewardsId: 2,
        rewardsName: "iPhone 15 (128GB)",
        rewardsDescription: "",
        rewardsLongDesc:
          "Grab this chance to win a brand-new iPhone with all the latest features.",
        noOfCoins: "300",
        coinTypeId: 1,
        coinTypeName: "General",
        quotaTypeId: 2,
        quotaTypeName: "PerWeek_Iphone",
        iconURL:
          "https://017526-content-dev.s3.ap-south-1.amazonaws.com/Rewards/Icons/iPhone15_128GB.webp",
        imageURL: "img04.webp",
        canClaim: false,
        rewardsDisplayOrder: 2,
        isIdRequired: true,
        isPassportRequired: false,
        collectNow: true,
        enterNow: false,
        claimTimer: "00:00:00",
        claimed: false,
        watchVideo: false,
        startQuiz: false,
        displayMessage: "",
        isPromocode: false,
        type: "Bharat",
        promoCode: null,
        isRewardUnlocked: false,
        quotaOver: false,
      },
      {
        rewardsId: 3,
        rewardsName: "Lollapalooza 2024",
        rewardsDescription: "Tickets",
        rewardsLongDesc:
          "Win a ticket to Lollapalooza music festival (Sep '24) in Berlin.",
        noOfCoins: "500",
        coinTypeId: 1,
        coinTypeName: "General",
        quotaTypeId: 3,
        quotaTypeName: "PerWeek_Lollpalooza",
        iconURL:
          "https://017526-content-dev.s3.ap-south-1.amazonaws.com/Rewards/Icons/Lollapalooza2024.svg",
        imageURL: "img02.webp",
        canClaim: false,
        rewardsDisplayOrder: 3,
        isIdRequired: true,
        isPassportRequired: false,
        collectNow: true,
        enterNow: false,
        claimTimer: "00:00:00",
        claimed: false,
        watchVideo: false,
        startQuiz: false,
        displayMessage: "",
        isPromocode: false,
        type: "Bharat",
        promoCode: null,
        isRewardUnlocked: false,
        quotaOver: false,
      },
      {
        rewardsId: 4,
        rewardsName: "Tomorrowland 2024",
        rewardsDescription: "All expenses paid trip",
        rewardsLongDesc:
          "Win an all-expenses paid couples trip to the biggest music festival of the year - Tomorrowland Summer (July '24) in Belgium.",
        noOfCoins: "",
        coinTypeId: 1,
        coinTypeName: "General",
        quotaTypeId: 4,
        quotaTypeName: "Per3Months_Tomorrowland",
        iconURL:
          "https://017526-content-dev.s3.ap-south-1.amazonaws.com/Rewards/Icons/Tomorrowland2024.svg",
        imageURL: "img08.webp",
        canClaim: false,
        rewardsDisplayOrder: 4,
        isIdRequired: false,
        isPassportRequired: true,
        collectNow: true,
        enterNow: false,
        claimTimer: "00:00:00",
        claimed: false,
        watchVideo: false,
        startQuiz: false,
        displayMessage: "",
        isPromocode: false,
        type: "Bharat",
        promoCode: null,
        isRewardUnlocked: false,
        quotaOver: false,
      },
    ],
  },
];
