import toast from "react-hot-toast";

//limit the input field 
export const handleInputLimitedLength = (event, minLength, maxLength) => {
  const inputValue = event.target.value;
  // Limit the input value to the maximum length
  let limitedValue = inputValue.slice(0, maxLength);
  // Enforce minimum length
  if (limitedValue.length < minLength) {
    limitedValue = inputValue.slice(0, minLength);
  }
  // Update the input value with the limited value
  event.target.value = limitedValue;
};

// Mobile number validation
export const handleKeyPressMobileNumber = (event) => {
  const currentValue = event.target.value;

  // Remove any non-digit characters and limit the value to a maximum of 10 digits
  const filteredValue = currentValue.replace(/\D/g, "").slice(0, 10);

  // Check if the filtered value is '000' and handle accordingly
  if (/^0{3}$/.test(filteredValue)) {
    event.preventDefault();
    console.error("Invalid mobile number");
    return;
  }

  // Update the input value with the filtered value
  event.target.value = filteredValue;
};

export const handleKeyPressPincode = (event) => {
  const currentValue = event.target.value;
  // Remove any non-digit characters and limit the value to a maximum of 6 digits
  const filteredValue = currentValue.replace(/\D/g, "").slice(0, 6);
  if (/^0+$/.test(filteredValue)) {
    event.preventDefault();
    console.error("In valid pincode");
    return;
  }

  // Update the input value with the filtered value
  event.target.value = filteredValue;
};

export const handleKeyPressName = (event) => {
  const inputValue = event.target.value;
  const filteredValue = inputValue.replace(/[^a-zA-Z0-9\s]/g, "").slice(0, 30);
  // Update the input value with the filtered value
  event.target.value = filteredValue;
};


export const handleErrorMessages = (res, setFieldError, key) => {
  let message = res.data && res.data.message
  setFieldError(key, message);
};
export const formatLargeNumber = (count) => {
  if (count < 1000) {
    return count.toString();
  } else if (count < 1000000) {
    return (count / 1000).toFixed(1) + "K";
  } else {
    return (count / 1000000).toFixed(1) + "M";
  }
};

export const convertKToNumeric = (kValue) => {
  if (typeof kValue === "string" && kValue.match(/(\d+(\.\d+)?)(K|M|B)?/)) {
    let match = kValue.match(/(\d+(\.\d+)?)(K|M|B)?/);
    let numericValue = parseFloat(match[1]);
    let modifier = match[3];
    if (modifier) {
      if (modifier === "K") {
        numericValue *= 1000;
      } else if (modifier === "M") {
        numericValue *= 1000000;
      } else if (modifier === "B") {
        numericValue *= 1000000000;
      }
    }
    return numericValue;
  }
  return null; // Return null for invalid input
};

export const isVideoNew = (createdTimestamp) => {
  let days = 7;
  const timestampDifference = days * 60 * 1000 * 60 * 24;
  if (Date.now() - createdTimestamp <= timestampDifference) return true;
  else return false;
};

export const showApiErrors = (res) => {
  let message = res.data && res.data.message;
  toast.error(message, { id: "showApiErrors error" });
}

export const returnMobileNumber = (data) => {
  if (data.alternateMobile) {
    return "+91 - " + data.mobileNo + ", +91 - " + data.alternateMobile;
  } else {
    return "+91 - " + data.mobileNo;
  }
};

export const getCDSPixelId = async () => {
  //cookieStore doesn't work for safari and firefox
  try {
    const fetchPixelId = await window.cookieStore.get("__cds_pixel_id");
    if (fetchPixelId) {
      return fetchPixelId?.value;
    } else {
      return "";
    }
  }
  catch (error) {
    console.error("pixel error:", error)
  }
}
export const handleShare = async (shareContent) => {
  try {
    const response = await fetch(shareContent.image);
    const blob = await response.blob();
    const filesArray = [new File([blob], "image.png", { type: "image/png" })];
    const shareData = {
      title: shareContent && shareContent.title,
      text: shareContent && shareContent.text,
    };
    if (shareContent.image) {
      shareData.files = filesArray;
    }

    if (shareContent.url) {
      shareData.url = shareContent.url;
    }
    // Handle successful share
  } catch (error) {
    console.error("Error sharing:", error);
    // Handle error while sharing
    // You can provide feedback to the user or log the error for debugging
  }
};

function normalizePath(path) {
  // Remove any existing double slashes
  path = path.replace(/\/\//g, "/");
  // Add a single slash if not present
  if (path.charAt(0) !== "/") {
    path = "/" + path;
  }
  return path;
}

// Define a function to add Tamil separators conditionally
export const addPathSeparators = (path) => {
  console.log("typeOfSite", path);
  // Your condition to determine if Tamil separators should be added
  //let siteType = localStorage.getItem("siteType");
  console.log("hitt added seperator")
  let typeOfSite = window.location.pathname.includes("/tamil") ? "tamil" : "bharat";
  console.log("hitt added seperator after", typeOfSite)

  if (typeOfSite) {
    //Add Tamil separators to the path
    console.log("case2", normalizePath(`/${typeOfSite}${path}`));
    return normalizePath(`/${typeOfSite}${path}`);
  } else {
    console.log("case3", normalizePath(`/${path}`));
    return normalizePath(`/${path}`);
  }
};


export const getRewardType = (rewardTitle) => {
  const rewardType = [
    { label: "Airpods", value: "Airpods" },
    { label: "iPhone", value: "iPhone" },
    { label: "Lollapalooza", value: "Lollapalooza" },
    { label: "Tomorrowland", value: "Tomorrowland" },
    { label: "TicketsCSKVIPTickets", value: "CSK VIP Tickets" },
    { label: "CSKMatchTickets", value: "CSK Match Tickets" },
  ];
  let rewardValue = rewardType.find((el) => el.value.includes(rewardTitle));
  return rewardValue;
};


export function base64ToFile(base64String) {
  if (!base64String) {
    console.error("Base64 string is missing.");
    return null;
  }

  // Split the base64 string to get the content type and the data
  const parts = base64String.split(';base64,');
  if (parts.length !== 2) {
    console.error("Invalid base64 string format.");
    return null;
  }

  const contentType = parts[0].split(':')[1];
  if (!contentType) {
    console.error("Content type is missing.");
    return null;
  }

  const raw = window.atob(parts[1]);
  const rawLength = raw.length;
  if (rawLength === 0) {
    console.error("Decoded data is empty.");
    return null;
  }

  const uint8Array = new Uint8Array(rawLength);
  for (let i = 0; i < rawLength; ++i) {
    uint8Array[i] = raw.charCodeAt(i);
  }

  // Create a Blob object to represent the file content
  const blob = new Blob([uint8Array], { type: contentType });

  // Create a File object from the Blob
  const file = new File([blob], "file", { type: contentType });

  return file;
}


export function base64ToImageSrc(base64String, contentType) {
  if (!base64String || !contentType) {
    console.error("Base64 string or content type is missing.");
    return null;
  }

  return `data:${contentType};base64,${base64String}`;
}


export const appendObjectToFormData = (formData, object) => {
  Object.entries(object).forEach(([key, value]) => {
    formData.append(key, value);
  });
};


export const getFileFormat = (file) => {
  const { type } = file;
  if (type.startsWith("video")) return 4;
  if (type.startsWith("audio")) return 3;
  if (type.startsWith("image")) return 1;
  return null;
};

export const getGaEventType = (file) => {
  const { type } = file;
  if (type.startsWith("video")) return "Video";
  if (type.startsWith("audio")) return "Audio";
  if (type.startsWith("image")) return "Image";
  return null;
};

//get lang id 
export const getlanguageId = (currentLanguage) => {
  console.log(currentLanguage, "currentLanguage")
  let languageId = 1;
  if (currentLanguage === 'hi' || currentLanguage === 'ba') {
    languageId = 2;
  } else {
    languageId = 1;
  }
  return languageId;
};

export const removeSpanFromString = (string) => {
  if (string) {
    const matchResult = string && string?.match(/<span>(.*?)<\/span>/);
    const filterString = matchResult ? matchResult[1] : string;
    return filterString;
  } else {
    return string;
  }
};
export function filterItemsBySeason(data, season, setData = () => {}) {
  // Ensure data and season are provided
  if (!data || !season) {
    return [];
  }

  // Normalize the season string for comparison
  const normalizeString = (str) => str.replace(/\s+/g, '').toLowerCase();
  const normalizedSeason = normalizeString(season);

  // Filter itemList based on the provided season
  const filteredItems = data && data?.itemList && data?.itemList?.filter(item => {
    return item?.video?.labels?.some(label => normalizeString(label) === normalizedSeason);
  });

  console.log(filteredItems, "filteredItems", data, season);

  // Return updated data with filtered itemList
  const updatedData = {
    ...data,
    itemCount: filteredItems && filteredItems.length,
    itemList: filteredItems
  };
  setData(updatedData);
  return updatedData;
}
