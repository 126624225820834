import en from '../lang/en.json'; // English
import hi from '../lang/hi.json'; //Hindi
import te from '../lang/te.json'; // Tamil
import bn from '../lang/bn.json'; //Bangla

const translations = {
  en,
  hi,
  te,
  bn
};


export default translations;