import React, { useState, useEffect } from "react";
import CommonSection from "../components/common/CommonSection";
import SectionHeader from "../components/common/SectionHeader";
import CommonTable from "../components/common/Table";
import { getAllreferralAPI, getLeaderBoardLists } from "../services";
import { getCreatorLeaderBoard } from "../services/gluedin-services";
import useLoader from "../utils/hooks/use-loader";

const CreatorLeaderBoard = (props) => {
  const [page, setPage] = useState(1);
  const [pageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const { toggleLoader } = useLoader();
  const [createorLeaderBoardList, setCreateorLeaderBoardList] = useState([]);

  const headerContent = {
    title: "Creator Leaderboard",
    subTitle: "Lifetime Uploads and Likes Earned",
    // image: '/assets/img/icons/leaderboard-icon.svg',
    linkText: `${props.linkText ? "See All" : ""}`,
    titleClass: "text-left text-md-center",
    link: "/creator_leaderboard",
  };

  useEffect(() => {
    getCreatorLeaderBoard(
      toggleLoader,
      page,
      pageSize,
      setCreateorLeaderBoardList,
      setTotalCount
    );
  }, [page, pageSize]);

  const loadMore = async () => {
    setPage(page + 1);
  };

  // limit: 10
  // offset: 1
  const renderReferralsInfo = () => {
    const header = true;
    const headerBackgroundColor = "black";
    const headerFontColor = "white";
    const columns = ["Names", "Rank", "Uploads", "Views", "Likes"];
    const columnName = [
      "user",
      "rank",
      "videoCount",
      "viewCount",
      "likeCount",
    ];
    const tableClassName = "custom-table mb-8 text-center profile-table";
    console.log(createorLeaderBoardList, "createorLeaderBoardList");

    return (
      <>
        <CommonTable
          header={header}
          headerBackgroundColor={headerBackgroundColor}
          headerFontColor={headerFontColor}
          rows={createorLeaderBoardList}
          columns={columns}
          tableClassName={tableClassName}
          columnName={columnName}
          loadMore={loadMore}
          totalCount={totalCount}
          setPage={setPage}
          page={page}
          pageSize={pageSize}
          showImage={true}
          showPagination={props.hidePagination}
        />
      </>
    );
  };

  return (
    <>
      <CommonSection
        headerContent={headerContent}
        headerType={3}
        pageHeaderClass={props.pageHeaderClass ? "d-none" : ""}
        sectionClass="col-md-8"
        containerClass="mt-3 mt-md-15"
      >
        <SectionHeader
          {...headerContent}
          headerClass={
            props.headerClass
              ? `${props.headerClass}`
              : `d-none d-md-block pt-0 pb-6`
          }
          titleClass={props.titleClass}
          subTitleClass={props.titleClass}
        />
      </CommonSection>
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-12 col-md-8">{renderReferralsInfo()}</div>
        </div>
      </div>
    </>
  );
};

export default CreatorLeaderBoard;
