import React, { useState, useEffect } from "react";
import CommonTable from "../components/common/Table";
import { getAllreferralAPI } from "../services";
import useTranslation from "../utils/hooks/use-translation";
import { useNavigate } from "react-router";
import usePopup from "../utils/hooks/use-popup";

const ReferFriend = () => {
  const { openReferFriendPopup } = usePopup();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const [pageSize] = useState(7);
  const [referralList, setReferralList] = useState([]);
  const [allreferralData, setAllReferralData] = useState([]);

  useEffect(() => {
    getReferralList();
  }, [page, pageSize]);

  const getReferralList = async () => {
    try {
      let reqData = {
        PageNumber: page || 1,
        Pagesize: pageSize || 7,
      };
      const res = await getAllreferralAPI(reqData);
      if (res.status === 200) {
        let data =
          res.data &&
            res.data.data &&
            Array.isArray(res.data.data.data) &&
            res.data.data.data.length
            ? res.data.data.data
            : [];

        setAllReferralData(res.data.data);
        setReferralList(data);
      }
    } catch (err) { }
  };

  console.log(allreferralData, "allreferralData");
  const renderReferralsInfo = () => {
    const header = true;
    const headerBackgroundColor = "black";
    const headerFontColor = "white";
    const referrals =
      referralList &&
      referralList.length &&
      referralList.map((obj, index) => ({
        ...obj,
        s_no: `${(page - 1) * pageSize + index + 1}`.padStart(2, "0"),
        status: obj.isAccept ? (
          <span style={{ color: "#16C43D" }}>{t.common.accepted}</span>
        ) : obj.isResend ? (
          t.referal.send_again
        ) : (
          <span>{t.common.pending}</span>
        ),
      }));

    const columns = [t.common.s_no, t.common.mobile_no, t.common.status];
    const columnName = ["s_no", "referTo", "status"];
    const tableClassName =
      "custom-table mb-4 mb-md-8 text-md-center profile-table refer-table";

    return (
      <>
        <CommonTable
          header={header}
          headerBackgroundColor={headerBackgroundColor}
          headerFontColor={headerFontColor}
          rows={referrals}
          columns={columns}
          tableClassName={tableClassName}
          columnName={columnName}
          // loadMore={loadMore}
          totalCount={allreferralData && allreferralData.totalCount}
          setPage={setPage}
          page={page}
          pageSize={pageSize}
        // showPagination={showPagination}
        />
        {referralList && referralList.length !== 0 ? (
          <div className="row justify-content-center mb-3 mb-md-0">
            <button
              className={"btn btn-primary mr-3 btn-refer"}
              onClick={() => navigate("/send-reminder")}
            >
              {t.referal.send_reminder}
            </button>
            <button
              className={"btn btn-outline-primary btn-refer"}
              onClick={() => openReferFriendPopup()}
            >
              {t.referal.refer_another}
            </button>
          </div>
        ) : (
          <div className="row justify-content-center mb-3 mb-md-0">
            <button
              className={"btn btn-outline-primary btn-refer"}
              onClick={() => openReferFriendPopup()}
            >
              {t.referal.refer_a_friend}
            </button>
          </div>
        )}
      </>
    );
  };

  return (
    <>
      {renderReferralsInfo()}
    </>
  );
};

export default ReferFriend;
