import React, { useState } from 'react';
import AppModal from '../../modals';
import CommonFormikForm from '../../../utils/form';
import { uniqueCodeValidationSchema } from '../../../utils/validations';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { mixCodeApi } from '../../../services';
import useLoader from '../../../utils/hooks/use-loader';
import { getAllHomepageDetailsALogin } from '../../../utils/axios-helper/api-hander'
import { useDispatch } from 'react-redux';
import useTranslation from '../../../utils/hooks/use-translation';
import { getCDSPixelId, handleInputLimitedLength } from '../../../utils/helpers';
import SuccessModel from '../../modals/api_response_model'
import EarnModal from '../../modals/EarnModal';

const UniqueCodeModal = (props) => {

  const { t } = useTranslation();
  const { show, onClose = () => { }, type } = props;
  const { toggleLoader } = useLoader();
  const dispatch = useDispatch()
  const [showsuccessModel, setShowSuccessModel] = useState({
    show: false,
    modelContent: {},
  });
  const [dailyLimitPopup, setDailyLimitPopup] = useState({
    show: false,
    modelContent: {},
  })

  const initialValues = {
    UniqueCode: '',
  };

  //handle unique code submit api call
  const handleSubmit = async (values, { resetForm, setFieldError }) => {
    setFieldError('UniqueCode', "");
    const hfSessionKey = await getCDSPixelId();
    toggleLoader(true);
    try {
      const reqData = {
        mixcode: values.UniqueCode,
        hfSession: hfSessionKey || "",
      };
      const res = await mixCodeApi(reqData);
      if (res.data && res.data.succeeded) {
        console.log(res, "resresres mixcode");
        window.dataLayer.push({ event: 'CSBangla24_UniqueCode_Submit' });

        if (res?.data?.statusCode === 200) {
          setShowSuccessModel({
            show: true,
            modelContent: {
              image: "/assets/img/modal/levelled_up.svg",
              heading: "Congratulations!",
              subHeadingPart1: "For entering Unique Code from Bottle/ Influencer and winning",
              timer: false,
              id: 9,
              coins: 200
            }
          })

          getAllHomepageDetailsALogin(toggleLoader);
          resetForm();
        }

        else {
          let message = res.data && res.data.message;
          setFieldError('UniqueCode', message);
        }
      }
    } catch (err) {
      console.error(err
        , "err");
      if (err?.response?.data?.statusCode == 417) {

        setDailyLimitPopup({
          show: true,
          modelContent: {
            image: "/assets/img/modal/daily-limit.svg",
            heading: t.model_messages.sorry,
            subHeading: t.model_messages.daily_limit_reached,
            timer: false,
            cta: "",
            description: t.model_messages.try_again_tomorrow,
            id: 4
          }
        })

      }
    } finally {
      toggleLoader(false);
      // setFieldError('UniqueCode', "")
    }
  };

  const renderInputs = [
    {
      type: 'text',
      name: 'UniqueCode',
      placeHolder: 'Enter Unique code',
      label: t.contest.enter_unique_code,
    },
  ];

  let uniqueCodeValidation = uniqueCodeValidationSchema(t)

  return (
    <>
      {type === 'model' ? (
        <AppModal
          show={show}
          onClose={onClose}
          size='lg'
          gaClass='modal-content-unique-code'
          bodyClass="p-3 px-md-2 py-md-0 d-flex align-items-center  modal-unique-code">
          <div>
            <img src="/assets/img/icons/unique-code-img.svg" alt="unique code" className='d-block d-md-none left-img-mobile' />
            <img src="/assets/img/icons/unique-code-img-cropped.svg" alt="unique code" className='d-md-block d-none' style={{ borderRadius: '20px' }} />
          </div>
          <div className='mx-auto px-3 py-2 py-md-0'>
            <CommonFormikForm
              initialValues={initialValues}
              onSubmit={handleSubmit}
              renderInputs={renderInputs}
              validationSchema={uniqueCodeValidation}
              formClass=''
            />

            <div className="info-points">
              <div className="info-text mr-2 ml-0">Get a chance to Win</div>
              <div className="points-box points-box-black">
                <h6 className="points-num points-black info-card-pts">
                  200
                </h6>
                <img
                  src="/assets/img/icons/Layer 1.svg"
                  alt="coin"
                  className="points-sign info-card-sign"
                />
              </div>
            </div>
          </div>
        </AppModal>
      ) : type === 'input' ? (
        <Formik
          initialValues={initialValues}
          validationSchema={uniqueCodeValidation}
          onSubmit={handleSubmit}>
          {({ handleSubmit, values, touched, errors }) => {
            console.error(errors, "errors")
            return (
              <Form
                className="w-100 position-relative"
                autoComplete="off"
                onSubmit={handleSubmit}>
                {/* Input Field */}
                <Field name="inputField">
                  {({ field }) => (
                    <div className="unique-code-wrapper">

                      <input
                        placeholder={t.contest.enter_unique_code}
                        {...field}
                        type="text"
                        name={'UniqueCode'}
                        value={values.UniqueCode}
                        onInput={(e) => {
                          handleInputLimitedLength(e, 8, 8);
                        }}
                      />
                      <button
                        className={`btn btn-sm btn-danger unicodesubmitbd py-2 px-4`}
                        type="submit">     {t.common.submit}

                      </button>
                    </div>
                  )}
                </Field>
                <ErrorMessage
                  name={'UniqueCode'}
                  component="div"
                  className={
                    touched['UniqueCode'] && errors['UniqueCode']
                      ? 'text-danger mt-1'
                      : 'text-danger'
                  }
                />
              </Form>
            )
          }
          }
        </Formik>
      ) : (
        <div></div>
      )}
      {/* {showsuccessModel && showsuccessModel?.show && (
        <SuccessModel
          show={showsuccessModel && showsuccessModel.show}
          {...showsuccessModel?.modelContent}
          onClose={() => {
            setShowSuccessModel({ show: false, modelContent: '' })
            onClose()
          }
        }
        />
      )} */}
      {showsuccessModel && showsuccessModel?.show &&
        <EarnModal show={showsuccessModel && showsuccessModel.show}
          {...showsuccessModel?.modelContent}
          onClose={() => {
            setShowSuccessModel({ show: false, modelContent: '' })
            onClose()
          }
          } />}

      {
        dailyLimitPopup && dailyLimitPopup?.show &&
        <SuccessModel show={dailyLimitPopup && dailyLimitPopup.show}
          {...dailyLimitPopup?.modelContent}
          onClose={() => {
            setDailyLimitPopup({ show: false, modelContent: '' })
            onClose()
          }
          } />}

    </>
  );
};

export default UniqueCodeModal;
