import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import useTranslation from "../../utils/hooks/use-translation";
import { useNavigate } from "react-router-dom";
import { getUGCUserContent } from "../../services/ugc-services";
import { getContentByUserId } from "../../utils/axios-helper/api-hander";
import { useDispatch } from "react-redux";
import useLoader from "../../utils/hooks/use-loader";
import CommonSection from "../common/CommonSection";
import SectionHeader from "../common/SectionHeader";

function MusicVaultCard() {
  const { t } = useTranslation();
  const [popoverIndex, setPopoverIndex] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { toggleLoader } = useLoader();
  const [userUgcContent, setUserUgcContent] = useState([]);
  const togglePopover = (index) => {
    setPopoverIndex(index === popoverIndex ? null : index);
  };
  const headerContent = {
    title: t.header.your_music_valut,
    subTitle: t.header.check_status_of_uploads,
  };

  console.log(userUgcContent, "userUgcContent");
  useEffect(() => {
    getContentByUserId(dispatch, toggleLoader, setUserUgcContent);
  }, [dispatch, toggleLoader]);

  return (
    <>
      <CommonSection
        headerContent={headerContent}
        headerType={3}
        containerClass="mt-3 mt-md-15"
        sectionClass=""
      >
        <SectionHeader
          {...headerContent}
          headerClass={`d-none d-md-block pt-0 pb-6`}
        />
        <Container className="mt-5 px-0">
          {userUgcContent && userUgcContent.length > 0 ? (
            <Row>
              {userUgcContent.map((card, index) => (
                <Col key={index} className="col-lg-4 col-6 mb-3">
                  <div className="card">
                    <img
                      src={"/assets/img/ugc/default__user_ugc.jpg"}
                      className="card-img-top"
                      alt="Image"
                    />
                    <div className="card-body">
                      <div className="d-flex">
                        <h5 className="card-title">
                          Status:
                          <span
                            className={
                              card?.status === 2
                                ? "text-success"
                                : card?.status === 3
                                ? "text-danger ml-1"
                                : "text-danger ml-1" // Assuming you want to add a class for Pending
                            }
                          >
                            {card?.status === 1
                              ? "Pending"
                              : card?.status === 2
                              ? "Successful"
                              : card?.status === 3
                              ? "Rejected"
                              : "Unknown"}
                          </span>
                        </h5>
                        {/* <div className="popover-container d-inline-block">
                          <img
                            className="info-icon"
                            src={"/assets/img/icons/info-icon.svg"}
                            alt={"info"}
                            onClick={() => togglePopover(index)}
                          />
                          {popoverIndex === index && (
                            <div className="popover">
                              <div className="tooltip-arrow"></div>
                              {card.popoverText}
                            </div>
                          )}
                        </div> */}
                      </div>
                      <p className="card-text">
                        Submission Date:{" "}
                        {card?.createdOn
                          ? new Date(card.createdOn)?.toLocaleDateString(
                              "en-GB"
                            )
                          : "Not Found"}
                      </p>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          ) : (
            <div className="text-center mt-10 mb-5">
              <h2>
                No content available at the moment. Please upload something.
              </h2>
            </div>
          )}
        </Container>
      </CommonSection>
      <Button
        variant="primary"
        size="block"
        className="mt-8 btn-fixed"
        onClick={() => navigate("/creator_leaderboard")}
      >
        {t.common.check_rank}
      </Button>
    </>
  );
}

export default MusicVaultCard;
