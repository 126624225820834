import React, { useEffect, useState } from "react";
import moment from "moment";
import { Row, Button } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import SectionHeader from "../components/common/SectionHeader";
import useAuth from "../utils/hooks/use-auth";
import { getAddressByUserId } from "../services";
import useLoader from "../utils/hooks/use-loader";
import usePopup from "../utils/hooks//use-popup";
import useTranslation from "../utils/hooks//use-translation";
import { useNavigate } from "react-router-dom";
import AddressForm from "../components/common/appForm/AddressForm";
import { Formik } from "formik";
import { returnMobileNumber } from "../utils/helpers";
import ReferFriend from "../pages/ReferaFriend";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import CommonSection from "../components/common/CommonSection";

const CollegeMinicert = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isInputFocused, setIsInputFocused] = useState(false);

  const headerContent = {
    title: "College Minicert",
    subTitle: "Burn",
    subTitle2: "to vote for your college",
    headerClass: "profile-header",
    containerClass: "profile-header-container my-profile-header",
    pointsBox: true,
    tooltip: true,
    titleClass: "pb-0",
  };

  return (
    <div className="my-profile college-minicert">
      <CommonSection
        headerType={3}
        headerContent={headerContent}
        headerClass={
          "mt-n2 text-left send-reminder-header artist-header artist-details-header"
        }
        containerClass={`mt-6 mt-md-16 mt-lg-12`}
      >
        <SectionHeader
          {...headerContent}
          headerClass={"pt-0 pb-0 d-none d-lg-block"}
          containerClass={"px-0"}
        />
      </CommonSection>
      <div className={`container-fluid`}>
        <div className="row justify-content-center">
          <div className="col-12 col-md-12">
            <div className="card  mb-4 mt-md-5">
              <div className="card-body card bg-primary align-items-center p-0">
                <Row className="d-flex profile-card wallet justify-content-between align-items-center col-md-12">
                  <div className="d-flex align-items-center">
                    <img
                      src="/assets/img/icons/minicert-leaderboard-icon.svg"
                      alt="leader board icon"
                      className="cs-coin-image"
                    />
                    <h3 className="ml-3 text-light">{t.common.leaderboard}</h3>
                  </div>
                  <Link
                    className="btn btn-outline-primary py-3 px-4"
                    to={"/college_minicert_leaderboard"}
                  >
                    {t.common.check_here}
                  </Link>
                </Row>
              </div>
            </div>
            <div
              className={`text-left search-wrapper mb-4 ml-auto ${
                isInputFocused ? "search-focused" : ""
              }`}
            >
              {!isInputFocused && (
                <img src="/assets/img/icons/search.svg" alt="Search Icon" />
              )}
              <input
                className="search"
                type="text"
                placeholder="Search by Name..."
                onFocus={() => setIsInputFocused(true)}
                onBlur={() => setIsInputFocused(false)}
              />
            </div>

            <div className="card  mb-2 mb-3">
              <div className="card-body card bg-light align-items-center p-0">
                <div className="d-flex profile-card justify-content-between align-items-center py-4 pl-3 pr-5">
                  <div className="d-flex align-items-center">
                    <h2 className="first-letter">S</h2>
                    <h4 className="ml-3">Shri Ram College of Commerce</h4>
                  </div>
                  <img
                    src="/assets/img/icons/heart.svg"
                    alt="leader board icon"
                    className="cs-coin-image"
                  />
                </div>
              </div>
            </div>
            <div className="card  mb-2 mb-3">
              <div className="card-body card bg-light align-items-center p-0">
                <div className="d-flex profile-card justify-content-between align-items-center py-4 pl-3 pr-5">
                  <div className="d-flex align-items-center">
                    <h2 className="first-letter">S</h2>
                    <h4 className="ml-3">Shri Ram College of Commerce</h4>
                  </div>
                  <img
                    src="/assets/img/icons/heart.svg"
                    alt="leader board icon"
                    className="cs-coin-image"
                  />
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-center align-items-center mt-6 mt-md-8 pb-5">
              <Button
                variant="primary"
                size="md"
                className="btn btn-primary py-3 px-9 mr-5"
              >
                {t.common.previous}
              </Button>
              <Button
                variant="primary"
                size=""
                className="btn btn-primary py-3 px-9"
              >
                {t.common.next}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CollegeMinicert;
