import React from 'react';
import { Nav, NavDropdown, Image } from 'react-bootstrap';
import useAuth from '../../utils/hooks/use-auth';
import usePopup from '../../utils/hooks/use-popup';
import { useDispatch } from 'react-redux';
import { setAfterLoginAction } from '../../store/slices/common-slice';
import { useNavigate } from 'react-router-dom';


const NavLinks = (props) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { data, navigateTo } = props;
  const { openReferFriendPopup } = usePopup();
  const { isLoggedIn } = useAuth()
  if (!data || !Array.isArray(data)) {
    console.error('Invalid or missing data array.');
    return null;
  }

  return (
    <>
      <Nav className="me-auto mt-5" as={'ul'}>
        {data.map((el, i) =>
          el && !el.subMenu ? (
            <Nav.Item as="li" key={i}>
              <Nav.Link href={el.url} onClick={() => navigateTo()}>
                <Image className="icon" src={el.image} alt={el.title} />
                <span>{el.title}</span>
              </Nav.Link>
            </Nav.Item>
          ) : (
            <NavDropdown
              as="li"
              key={i}
              title={
                <>
                  <Image className="icon" src={el.image} alt={el.title} />
                  <span>{el.title}</span>
                </>
              }>
              {/* {el.subMenu?.map((item, j) => (
                <NavDropdown.Item key={j} href={item.url}>
                  <span dangerouslySetInnerHTML={{ __html: item.title }} onClick={() => navigateTo()}/>
                </NavDropdown.Item>
              ))} */}
              {el.subMenu?.map((item, j) => {
                return (
                  <NavDropdown.Item key={j}
                    href={
                      (!isLoggedIn && item?.authentication === true)
                        ? `/login`
                        : item.url
                      // item.url
                    }
                  >
                    <span
                      dangerouslySetInnerHTML={{ __html: item.title }}
                      onClick={() => {
                        if (item.id === 7) {
                          if (isLoggedIn) {
                            openReferFriendPopup()
                          } if (item.id === 5 && !isLoggedIn) {
                            dispatch(
                              setAfterLoginAction({
                                action: '',
                                redirectPath: '/artist',
                              })
                            );
                          } else {
                            dispatch(
                              setAfterLoginAction({
                                action: openReferFriendPopup,
                                redirectPath: '',
                              })
                            );
                          }
                        }


                        navigateTo();
                      }}
                    />
                  </NavDropdown.Item>
                )
              }
              )}
            </NavDropdown>
          )
        )}
      </Nav>
    </>
  );
};

export default NavLinks;
