import React from "react";
import AppModal from "./index";
import TimerComponent from "../common/Timer";
import useTranslation from "../../utils/hooks/use-translation";
import PointsBox from "../common/PointsBox";

const EarnModal = ({
  coins,
  show,
  onClose,
  image,
  heading,
  subHeadingPart1,
  subHeadingPart2,
  subHeadingPart3,
  subHeading2,
  cta,
  cta2,
  timer,
  description,
  timerContent,
}) => {
  const { t } = useTranslation();

  const targetTime = new Date("2024-01-30T23:59:59").getTime();
  return (
    <AppModal
      show={show}
      onClose={onClose}
      bodyClass="px-6 pt-0 pb-md-5 pb-3 modal-body-success"
      size="md"
    >
      <div className="text-center">
        {image && (
          <img
            src={image}
            alt="Icon"
            className="modal-icon mb-md-4 mb-2 modal-image"
          />
        )}
        {heading && <h6 className="modal-heading pb-2">{heading}</h6>}
        {subHeadingPart1 && subHeadingPart2 && (
          <>
            <div className="d-flex align-items-center justify-content-center ">
              <p
                className="modal-description pb-md-0 pb-0 mb-0 pr-2"
                dangerouslySetInnerHTML={{ __html: subHeadingPart1 }}
              />
            </div>
            <p
              className="modal-description pb-md-0 pb-0 mb-0 pr-2"
              dangerouslySetInnerHTML={{ __html: subHeadingPart2 }}
            />
          </>
        )}
        {subHeadingPart1 && subHeadingPart3 && (
          <>
            <div className=" pb-md-3 pb-1 mx-md-8">
              <p
                className="modal-description pb-md-0 pb-0 mb-0 pr-2"
                dangerouslySetInnerHTML={{ __html: subHeadingPart1 }}
              />

              <div
                className=" d-inline-block position-relative"
                style={{ top: "-4px" }}
              ></div>
              <span className="modal-description pb-md-0 pb-0 mb-0 pl-2">
                {subHeadingPart3}
              </span>
            </div>
          </>
        )}
        {subHeadingPart1 && !subHeadingPart2 && !subHeadingPart3 && (
          <>
            <div className=" pb-3 mx-md-7">
              <p
                className="modal-description pb-md-0 pb-0 mb-0 pr-2"
                dangerouslySetInnerHTML={{ __html: subHeadingPart1 }}
              />

              <div
                className=" d-inline-block position-relative"
                style={{ top: "-3px" }}
              ></div>
            </div>
          </>
        )}
        {timer && (
          <p className="modal-notification pb-md-2 pb-1">
            <TimerComponent
              title={t.common.claim_within}
              targetTime={targetTime}
            />
          </p>
        )}
        {timerContent && (
          <p className="modal-notification pb-md-2 pb-1">{timerContent}</p>
        )}
        {subHeading2 && (
          <p className="modal-description pb-md-2 pb-1">{subHeading2}</p>
        )}
        <div className="d-flex align-items-center justify-content-center">
          {cta2 && (
            <button className="btn btn-modal btn-outline-primary mb-3 mr-5">
              {cta2}
            </button>
          )}
          {cta && (
            <button
              className="btn btn-primary mb-3 btn-modal"
              onClick={() => {
                onClose();
              }}
            >
              {cta}
            </button>
          )}
        </div>
        {description && (
          <p
            className="modal-notification"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        )}
      </div>
    </AppModal>
  );
};

export default EarnModal;
