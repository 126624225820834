import React from "react";
import AppModal from "./index";
import TimerComponent from "../common/Timer";
import useTranslation from "../../utils/hooks/use-translation";

const ErrorModel = ({
  show,
  onClose,
  image,
  heading,
  subHeading,
  subHeading2,
  referralCode,
  cta,
  timer,
  description,
  timerContent,
  onAction=()=>{}
}) => {
  const { t } = useTranslation();

  const targetTime = new Date("2024-01-30T23:59:59").getTime();
  return (
    <AppModal
      show={show}
      onClose={onClose}
      bodyClass="px-6 pt-0 pb-md-5 pb-3 modal-body-success"
      size="md"
    >
      <div className="text-center">
        {image && (
          <img src={image} alt="Icon" className="modal-icon mb-md-4 mb-2" />
        )}
        {heading && <h6 className="modal-heading pb-md-3 pb-2">{heading}</h6>}
        {subHeading && (
          <p className="modal-description pb-md-2 pb-1">{subHeading}</p>
        )}
        {subHeading2 && (
          <p className="modal-description pb-md-2 pb-1">{subHeading2}</p>
        )}
        {referralCode && (
          <p className="modal-description pb-md-2 pb-1 text-primary font-weight-bolder">{`“${referralCode}”`}</p>
        )}
        {timer && (
          <p className="modal-notification pb-md-2 pb-1">
            <TimerComponent
              title={t.common.claim_within}
              targetTime={targetTime}
            />
          </p>
        )}
        {timerContent && (
          <p className="modal-notification pb-md-2 pb-1">{timerContent}</p>
        )}
        {cta && (
          <button className="btn btn-primary mb-2 btn-modal"
          onClick={() => {
            onAction()
          }}
          >{cta}</button>
        )}
        {description && <p className="modal-notification">{description}</p>}
      </div>
    </AppModal>
  );
};

export default ErrorModel;
