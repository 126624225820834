import React, { useState, useEffect } from "react";
import { Container, ProgressBar } from "react-bootstrap";
import PointsBox from "./PointsBox";
import useTranslation from "../../utils/hooks/use-translation";
import useAuth from "../../utils/hooks/use-auth";

const Stepper = () => {
  const [logins, setLogins] = useState(0);
  const { userDetails } = useAuth();
  const t = useTranslation;
  const [isPopoverVisible, setPopoverVisibility] = useState(false);
  const togglePopover = () => {
    setPopoverVisibility(!isPopoverVisible);
  };
  const totalLoginCount =
    userDetails && userDetails?.loginCount ? userDetails?.loginCount : 0;
  console.log(userDetails, "userDetails");
  useEffect(() => {
    const simulateLogins = () => {};

    simulateLogins();
  }, []);

  const stepsCompleted = Math.min(totalLoginCount, 3); // Ensure steps don't exceed 3

  return (
    <div className="position-relative">
      <header className="s-header">
        <div className="container-fluid d-flex justify-content-between px-md-8">
          <h3 className="display-4 d-flex">
            You're In The Groove!
            <div className="d-inline-block ml-1">
              <PointsBox coin={25} />
            </div>
          </h3>

          <div className="popover-container d-inline-block">
            <img
              className="info-icon"
              src={"/assets/img/icons/info-icon.svg"}
              alt={"info"}
              onClick={togglePopover}
            />
            {isPopoverVisible && (
              <div className="popover">
                <div className="tooltip-arrow"></div>
                Come for music, stay for rewards! Log in 3 times weekly and earn
                up to 25 Coke® Coins.
              </div>
            )}
          </div>
        </div>
      </header>
      <p className="text-left m-0 info-text mb-1">Weekly login</p>
      <Container>
        <ProgressBar className="custom-progress-bar">
          <ProgressBar
            variant={stepsCompleted >= 1 ? "danger" : "#827e7e"}
            key={1}
            now={33.33} // Assuming each step is 1/3 of the bar
            label={<span className="progress-label">1st time login</span>}
          />
          <ProgressBar
            variant={stepsCompleted >= 2 ? "danger" : "#827e7e"}
            key={2}
            now={33.33} // Assuming each step is 1/3 of the bar
            label={<span className="progress-label">2nd time login</span>}
          />
          <ProgressBar
            variant={stepsCompleted >= 3 ? "danger" : "#827e7e"}
            key={3}
            now={33.33} // Assuming each step is 1/3 of the bar
            label={<span className="progress-label">3rd time login</span>}
          />
        </ProgressBar>
      </Container>
    </div>
  );
};

export default Stepper;
