import { AxiosInstance } from "../utils/axios-helper/axios-helper";
import { API_END_POINT } from "../utils/constants/api-end-points";

export const getUGCContent = async (data) => {
    return await AxiosInstance.post(API_END_POINT.ugcGetContent, data);
  };
  
  
  export const getUGCUserContent = async (data) => {
    return await AxiosInstance.post(API_END_POINT.ugcUserContent, data);
  };

export const likeContentById = async (data) => {
  return await AxiosInstance.post(`${API_END_POINT.likeContentById}?AssetId=${data.AssetId}&URL=${data.URL}&Title=${data.Title}&IsLiked=${data.IsLiked}`)
};

export const shareContentById = async (data) => {
  return await AxiosInstance.post(`${API_END_POINT.shareContentById}?AssetId=${data.AssetId}&URL=${data.URL}&Title=${data.Title}&IsShare=${data.IsShare}`)
};
export const ugcUploadFile = async (data) => {
  return await AxiosInstance.post(API_END_POINT.ugcUpload,data)
};


export const getUgcUploadOwnerData = async(data)=>{
  return await AxiosInstance.post(API_END_POINT.getUGCUserDetailByUserIds,data)
}