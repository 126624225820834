// Importing AxiosInstance from axios-helper
import { AxiosInstance } from "../utils/axios-helper/axios-helper";
// Importing API endpoint constants
import { API_END_POINT } from "../utils/constants/api-end-points";
// Importing version from config
import { version } from '../config';
// Importing CryptoJS for encryption
import CryptoJS from 'crypto-js';
// Importing gluedin library
import gluedin from 'gluedin';
import { getProfile } from "./profile-service";
import { setUser } from "../store/slices/auth-slice";
import { setFollowedArtistDetails } from "../store/slices/common-slice";
import { store } from "../store";

// Initializing gluedin authentication and user modules
const authModule = new gluedin.GluedInAuthModule();
const userModule = new gluedin.GluedInUserModule();

// Function to send OTP
export const sendOtp = async (data) => {
  return await AxiosInstance.post(`${API_END_POINT.sendOtp}`, data);
};

// Function to verify OTP
export const verifyOTP = async (data) => {
  return await AxiosInstance.post(API_END_POINT.verifyOtp, data);
};

// Function to register user
export const registerUser = async (data) => {
  return await AxiosInstance.post(`${API_END_POINT.registerUser}`, data);
};

// Function to get avatar list
export const getAvatarList = async () => {
  return await AxiosInstance.get(`${version}${API_END_POINT.avatarList}`);
};

// Function to validate referral code
export const validateReferCode = async (code) => {
  return await AxiosInstance.get(`${API_END_POINT.validateRefCode}?code=${code}`);
};

// Function for gluedin login
export const gluedinLogin = async (reqData) => {
  // Secret key for encryption
  const secretKey = process.env.REACT_APP_GLUEDIN_LOGIN_SECRET_KEY;
  // const secretKey = "d20e13d13ad4f99febe19aa0fefbcab7f7c05f222b75cfe5d9a54ba20d715c31"
  // Encryption function

  const encrypt = async (text, secretKey) => {
    return CryptoJS.AES.encrypt(text, secretKey).toString();
  };
  let data = await encrypt(window.location.origin, secretKey);
  console.log(data);
  // const authLoginInput = new gluedin.GluedInAuthModule();
  const signInRequest = {
    email: reqData.email,
    password: reqData.password,
    autoCreate: true,
    accessToken: data
  };
  await authModule.AuthRawData(signInRequest);
  const userConfigResponse = await userModule.getUserMetaIds();
  console.log(userConfigResponse, "userConfigResponse")
  if (userConfigResponse.status === 200 || userConfigResponse.status === 201) {
    const following = userConfigResponse.data.result.following || [];
    store.dispatch(setFollowedArtistDetails(following))
  }
}

export const getUserDetails = async (toggleLoader = () => { }, userDetails, dispatch) => {
  toggleLoader(true);
  try {
    let reqData = {
      userId: userDetails && userDetails.id,
    };
    const res = await getProfile(reqData);
    console.log(res, "res getUserDetails")
    if (res.status === 200) {
      let data = res.data && res.data;
      console.log(data, "data getProfile");
      dispatch(setUser(data));
      let formData = {
        email: `${userDetails && userDetails.id}@gmail.com`,
        password: userDetails && userDetails.id,
      };
      gluedinLogin(formData);
    }
  } catch (err) {
  } finally {
    toggleLoader(false);
  }
};
// Function for gluedin logout
export const gluedinLogout = () => {
  // Removing items from session storage
  sessionStorage.removeItem('guestToken');
  sessionStorage.removeItem('community-closed');
  sessionStorage.removeItem('uniqueToken');
  sessionStorage.removeItem('userDetails');
  sessionStorage.removeItem('userData');
  sessionStorage.removeItem('hdkjfsdhfkhdskfhkdshfkdsgcvbjhsdbvdfkjh');
};
