import useTranslation from "./use-translation";
import { useNavigate } from "react-router-dom";
import usePopup from "./use-popup";
import useLoader from "./use-loader";
import { useDispatch } from "react-redux";
import { contentRejectedPopup, contentSuccessullyPostedPopup, earnedCoinOnLikePopup, earnedCoinOnSharePopup, profileCompletionPopup, reachedLimitOnLikeEarnPopup, reachedLimitOnShareEarnPopup, referSuccessPopup, referralAcceptedCoins100, referralSignupBonus, referralSignupBonus300, signupSuccessContentType, weeklyLoginRewardEarnPopup } from "../constants/popup-content";

const useSuccessHandler = () => {
  const dispatch = useDispatch();
  const { toggleLoader } = useLoader();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { openEarnModel, openUniqueCodePopup, openSuccessPopup, openStartQuizPopup } =
    usePopup();

  const handleEarnCoinModel = async (key,) => {
    let contentType = ''
    console.log(key, "key handleEarnCoinModel")
    // const openModel = (type) => {
    //   let contentType = earnModelContent(t).find((el) => el.id === type);
    // };

    if (key === 'registration') {
      contentType = signupSuccessContentType(t,
        openEarnModel(false, "")
      )
    }
    if (key === 'referralsAccepted') {
      contentType = referralAcceptedCoins100(t,
        openEarnModel(false, "")
      )
    }
    if (key === 'Referral') {
      contentType = referralSignupBonus300(t,
        openEarnModel(false, "")
      )
    }
    if (key === 'rewards') {

    } if (key === 'ugc') {
      contentType = contentSuccessullyPostedPopup(
        t, openEarnModel(false, "")
      )
    }
    if (key === 'ugcRejected') {
      contentType = contentRejectedPopup(
        t, openEarnModel(false, "")
      )
    } if (key === 'profileCompleted') {
      contentType = profileCompletionPopup(
        t, openEarnModel(false, "")
      )
    } if (key === 'earnedCoinOnLike') {
      contentType = earnedCoinOnLikePopup(t, openEarnModel(false, ""))
    } if (key === 'earnedCoinOnShare') {
      contentType = earnedCoinOnSharePopup(t, openEarnModel(false, ""))
    } if (key === 'reachedLimitOnLikeEarn') {
      contentType = reachedLimitOnLikeEarnPopup(t, openEarnModel(false, ""))
    } if (key === 'reachedLimitOnShareEarn') {
      contentType = reachedLimitOnShareEarnPopup(t, openEarnModel(false, ""))
    } if(key === 'weeklyLoginReward'){
      contentType = weeklyLoginRewardEarnPopup(t, openEarnModel(false, ""))
    }

    await openEarnModel(true, contentType);
  }
  // Add other functions or state as needed

  return {
    handleEarnCoinModel: handleEarnCoinModel
    // Add other values or functions as needed
  };
};

export default useSuccessHandler;
