import React, { lazy, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useAuth from "../utils/hooks/use-auth";
import { fetchUgcContent } from "../services/gluedin-services";
import useLoader from "../utils/hooks/use-loader";
import { useViewport } from "../utils/context/viewport-context";
import Page404 from "./404";
import { useParams } from "react-router-dom";

const ReelsSection = lazy(() => import("../components/common/Carousel2"));

const Reels2 = () => {
  const allUgcData = useSelector((state) => state?.common?.allUgcData);
  const { isLoggedIn } = useAuth();
  const dispatch = useDispatch();
  const { isMobile } = useViewport();
  const { toggleLoader } = useLoader();
  const { reel_id } = useParams();
  const [updatedItemList, setUpdatedItemList] = useState([]);

  useEffect(() => {
    if (allUgcData?.itemList?.length) {
      const itemListCopy = allUgcData?.itemList?.map(item => ({ ...item }));
      const index = itemListCopy?.findIndex((item) => item.videoId === reel_id);
      if (index !== -1) {
        const [item] = itemListCopy.splice(index, 1);
        itemListCopy.unshift(item);
      }
      setUpdatedItemList(itemListCopy);
    }
  }, [reel_id]);

  return (
    isMobile ? (
      <div className="col-lg-5 mx-auto px-0">
        <ReelsSection
          data={updatedItemList.length ? updatedItemList : []}
          likedVideos={allUgcData?.likedVideos || 0}
          allData={allUgcData}
          sliderType={1}
          sliderClassName="carsouel-class"
          fullWidthVideo={false}
          cardType="mixed"
          id="spotlight"
          SliderContainerClass="px-0 slider-gutter-20"
          gaClass="Spotlight"
          isHeader={false}
          fetchAllVedios={() =>
            fetchUgcContent(isLoggedIn, dispatch, toggleLoader, {
              ugc: true,
              limit: 10000,
            })
          }
          actionOnTop={false}
          isExclusive={false}
          responsiveClass="carsouel-class"
          enableUgcEarnings={true}
          isLocked={false}
        />
      </div>
    ) : (
      <Page404 />
    )
  );
};

export default Reels2;
