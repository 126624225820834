import toast from "react-hot-toast";
import { getHomepageDetailsAfterLogin, getNotifyUserDetails, getWalletData } from "../../services";
import { setHomePageDetails, setRewardDetails } from "../../store/slices/common-slice";
import { LANGUAGEID } from "../../config";
import { getCDSPixelId, getlanguageId } from "../helpers";
import { getUGCUserContent } from "../../services/ugc-services";
import useSuccessHandler from "../hooks/use-success-model";
import { store } from "../../store";


/**
 * @method apiErrorHandler
 * @description handle api error
 */


export const apiErrorHandler = (res) => {
  if (res && res.message) {

    if (res?.response?.data?.message) {
      toast.error(res?.response?.data?.message, { id: "Error Message" })
    } else {
      toast.error(res.message, { id: "Error Message" })
    }
  } else {
    toast.error('Something went wrong please try again later', { id: "something went wrong err" })
  }
};

export const getRewardListAPI = async (dispatch, toggleLoader) => {
  toggleLoader(true);
  const hfSessionKey = await getCDSPixelId();
  const reqData = { hfSession: hfSessionKey || '', langid: 1 };
  try {
    const res = await getWalletData(reqData);
    if (res.status === 200) {
      let rewardList = res.data && res.data.data && res.data.data.rewards
      dispatch(setRewardDetails(rewardList))
    }
  } catch (err) {
  } finally {
    toggleLoader(false);
  }
};
//get all home page after login
export const getAllHomepageDetailsALogin = async (toggleLoader) => {
  toggleLoader(true);
  try {
    let reqData = { languageId: LANGUAGEID };
    const res = await getHomepageDetailsAfterLogin(reqData);
    if (res.status === 200 && res.data.succeeded) {
      let section = res.data && res.data.data && res.data.data.sections
      let notificationCount = res.data && res.data.data && res.data.data.newNotification
      let coinCount = res.data && res.data.data && res.data.data.walletCoins
      const updatedPayload = {
        sections: [...section], // Replace with the actual updated array
        notificationCount: notificationCount,
        coinCount: coinCount,
      };

      store.dispatch(setHomePageDetails(updatedPayload));
    }
  } catch (err) {
  } finally {
    toggleLoader(false);
  }
};


export const getContentByUserId = async (dispatch, toggleLoader = false, setUserUgcContent = () => { }) => {
  toggleLoader(true);
  try {
    const res = await getUGCUserContent();
    console.log(res, "res getContentByUserId")
    if (res.status === 200 && res.data.succeeded) {
      const ugcData = res && res?.data?.data
      setUserUgcContent(ugcData)
    }
  } catch (err) {
    console.error(err, "err")
    setUserUgcContent([])
  } finally {
    toggleLoader(false);
  }
};


export const getNotifyUser = async (toggleLoader = false, currentLanguage = 1, handleEarnCoinModel = () => { }) => {
  toggleLoader(true);
  try {
    let reqData = { languageId: getlanguageId(currentLanguage) };
    const res = await getNotifyUserDetails(reqData);
    console.log(res, "res getNotifyUser");
    if (res?.data?.statusCode === 200 && res.data.succeeded) {
      const data = res.data?.data?.[0];

      if (data) {
        const checks = {
          referralsAccepted:data.referralsAccepted,
          registration: data.registration,
          rewards: data.rewards,
          ugc: data.ugc,
          Referral: data.referral,
          ugcRejected:data.ugcRejected,
          profileCompleted:data.profileCompleted,
          earnedCoinOnLike:data.earnedCoinOnLike,
          earnedCoinOnShare:data.earnedCoinOnShare,
          reachedLimitOnLikeEarn:data.reachedLimitOnLikeEarn,
          reachedLimitOnShareEarn:data.reachedLimitOnShareEarn,
          weeklyLoginReward:data.weeklyLoginReward

        };

        for (const [key, value] of Object.entries(checks)) {
          if (value === undefined) {
            console.log(`${key} is not present`);
          } else if (value.length) {
            console.log(`${key} is present and not empty`);
            handleEarnCoinModel(key);
          } else {
            console.log(`${key} is present but empty`);
          }
        }
        getAllHomepageDetailsALogin( toggleLoader);
      } else {
        console.log("Invalid structure or data array is missing");
      }
    }
  } catch (err) {
    console.error(err, "err");
  } finally {
    toggleLoader(false);
  }
};
