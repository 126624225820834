import React, { useState } from "react";
import { Link } from "react-router-dom";
import DOMPurify from "dompurify";
import useTranslation from "../../utils/hooks/use-translation";
import PointsBox from "./PointsBox";

const SectionHeader = (props) => {
  const { t } = useTranslation();
  const {
    title,
    image,
    tooltip,
    subTitle,
    subTitle2,
    linkText,
    linkUrl = "",
    link,
    containerClass,
    headerClass,
    titleClass,
    subTitleClass,
    variant,
    btnClass,
    handleFilter,
    filter,
    as,
    isHeader,
    action = "",
    sectionId,
    pointsBox,
    titlePointBox,
  } = props;
  const isHeaderAvailble = isHeader !== undefined ? isHeader : true;
  const [isPopoverVisible, setPopoverVisibility] = useState(false);
  const togglePopover = () => {
    setPopoverVisibility(!isPopoverVisible);
  };

  return (
    <>
      {isHeaderAvailble && (
        <header className={`s-header ${headerClass || ""}`}>
          <div
            className={`container-fluid ${containerClass || ""} ${sectionId} ${
              image && "title-img-wrapper"
            }`}
          >
            {image && <img className="mr-md-6 mr-2" src={image} />}
            <div>
              {title && !tooltip && (
                <div
                  className={
                    titlePointBox &&
                    "d-flex align-items-center justify-content-md-center pb-1 pb-md-3"
                  }
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html: `<h3 class="s-title ${
                        titleClass || ""
                      }">${title}</h3>`,
                    }}
                  />
                  {titlePointBox && (
                    <div className="d-inline-block ml-2">
                      <PointsBox />
                    </div>
                  )}
                </div>
              )}
              {title && tooltip && (
                <div
                  className={`d-flex justify-content-lg-center align-items-center pb-2`}
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html: `<h3 class="s-title ${
                        titleClass || ""
                      }">${title}</h3>`,
                    }}
                  />
                  <div className="popover-container d-lg-inline-block d-none">
                    <img
                      className="info-icon"
                      src={"/assets/img/icons/info-icon.svg"}
                      alt={"info"}
                      onClick={togglePopover}
                    />
                    {isPopoverVisible && (
                      <div className="popover bg-light">
                        <div className="tooltip-arrow"></div>
                        College with maximum votes each quarter will get an
                        exclusive ‘Studio Bangla Explore’ event on their campus.
                      </div>
                    )}
                  </div>
                </div>
              )}
              {subTitle && !pointsBox && (
                <h4
                  className={`s-sub-title ${subTitleClass || ""}`}
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(subTitle),
                  }}
                ></h4>
              )}
              {subTitle && pointsBox && (
                <div className="text-nowrap">
                  <h4
                    className={`s-sub-title d-inline ${subTitleClass || ""}`}
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(subTitle),
                    }}
                  ></h4>
                  <div className="d-inline-block ml-2">
                    <PointsBox coin={100} />
                  </div>
                  {subTitle2 && (
                    <span className="s-sub-title d-inline ml-1">
                      {subTitle2}
                    </span>
                  )}
                </div>
              )}
              {linkText &&
                (action ? (
                  <Link
                    variant={variant}
                    as={as}
                    className={`btn-link ${btnClass || ""}`}
                    to={linkUrl}
                    onClick={() => action()}
                  >
                    <span>{linkText}</span>
                  </Link>
                ) : (
                  <Link
                    variant={variant}
                    as={as}
                    className={`btn-link ${btnClass || ""}`}
                    to={link}
                  >
                    <span>{linkText}</span>
                    <span className="icon icon-Right icon-Right-arrow-01"></span>
                  </Link>
                ))}

              {handleFilter && (
                <select
                  id="filter-dropdown"
                  className="select mt-1 mt-md-7"
                  value={filter}
                  onChange={(e) => handleFilter(e.target.value)}
                >
                  <option value="all">{t.common.all}</option>
                  <option value="most_popular">{t.common.most_popular}</option>
                  <option value="most_viewed">{t.common.most_viewed}</option>
                  <option value="most_liked">{t.common.most_liked}</option>
                </select>
              )}
            </div>
          </div>
        </header>
      )}
    </>
  );
};
export default SectionHeader;
