import * as yup from "yup";
import { REGEX } from "../constants/validation-regex";
import { validateReferCode } from "../../services";
import toast from "react-hot-toast";

//OTP VALIDATION
export const otpValidationSchema = (t) => {
  return yup.object({
    otp: yup
      .string()
      .required(t.validation_messages.valid["otp"])
      .test(
        "otp-digit-length",
        () => t.validation_messages.valid["otp"],
        (value) => {
          if (value) {
            if (`${value}`.length !== 6) {
              return false;
            }
          }
          return true;
        }
      ),
  });
};

export const ReferralValidationSchema = (t) => {
  return yup.object({
    mobileCode: yup
      .string()
      //  .min(10, `${t.validation_messages.valid["mobile"]}`)
      //  .max(10, `${t.validation_messages.valid["mobile"]}`)
      .matches(REGEX.mobile, t.validation_messages.valid["mobile"])
      .required(t.validation_messages.required["mobile"]),
    email: yup
      .string()
      .email(t.validation_messages.valid["email"])
      .matches(REGEX.email, t.validation_messages.valid["email"]),
  });
};

//sign-up  validations
export const signUpValidationSchema = (t) => {
  return yup.object({
    name: yup
      .string()
      .matches(REGEX.nameWithSpace, t.validation_messages.valid["publicName"])
      .required(t.validation_messages.required["publicName"])
      .nullable(),
    // email: yup
    //   .string()
    //   .email(t.validation_messages.valid["email"])
    //   .matches(REGEX.email, t.validation_messages.valid["email"])
    //   .required(t.validation_messages.required["email"]),
    AgreeTerms: yup
      .bool()
      .oneOf([true], t.validation_messages.required["terms_conditions"]),
    referalCode: yup.string().test(
      "validateReferalCode",
      // t.validation_messages.required["refer_code"],
      async function (value) {
        if (value) {
          try {
            const res = await validateReferCode(value);
            if (res.data && res.data.succeeded === false) {
              return false; // Invalid referral code
            }
          } catch (err) {
            // Handle error if needed
            // toast.error(err?.response?.data?.message)
            console.error(err);
            return false; // Invalid referral code
          }
        }

        return true; // Valid referral code or empty
      }
    ),
  });
};

//address validation schema
export const addressValidationSchema = (t) => {
  return yup.object({
    AddressOne: yup
      .string()
      .matches(REGEX.Address, t.validation_messages.valid["address"])
      .required(t.validation_messages.required["adress_line1"]),
    AddressTwo: yup
      .string()
      .matches(REGEX.Address, t.validation_messages.valid["address"])
      .nullable(),
    Near_Landmark: yup
      .string()
      .matches(REGEX.Address, t.validation_messages.valid["address"])
      .nullable(),
    mobileCode: yup
      .string()
      // .required(t.validation_messages.required["mobile"])
      .min(10, `${t.validation_messages.valid["mobile"]}`)
      .max(10, `${t.validation_messages.valid["mobile"]}`)
      .matches(REGEX.mobile, t.validation_messages.valid["mobile"])
      .nullable(),
    State: yup.string().required(t.validation_messages.required["state"]),
    City: yup.string().required(t.validation_messages.required["city"]),
    // Pincode: yup.string()
    //   .length(6, t.validation_messages.valid["pincode"])
    //   .required(t.validation_messages.required["pincode"]),

    // mobileCode : yup
    //   .string()
    //   .required(t.validation_messages.required["mobile"])
    //   .min(10, `${t.validation_messages.valid["mobile"]}`)
    //   .max(10, `${t.validation_messages.valid["mobile"]}`)
    //   .matches(REGEX.mobile, t.validation_messages.valid["mobile"])
    //   .nullable()
  });
};

//claim form validation
export const createValidationSchema = (
  isPassportFieldShow,
  isPanFieldShow,
  t
) => {
  return yup.object({
    Name: yup
      .string()
      .required("Name is required")
      .matches(REGEX.name, t.validation_messages.valid["name"]),
    mobile: yup
      .string()
      .min(10, `${t.validation_messages.valid["mobile"]}`)
      .max(10, `${t.validation_messages.valid["mobile"]}`)
      .matches(REGEX.mobile, t.validation_messages.valid["mobile"])
      .required(t.validation_messages.required["mobile"]),
    Email: yup
      .string()
      .email(t.validation_messages.valid["email"])
      .matches(REGEX.email, t.validation_messages.valid["email"])
      .required(t.validation_messages.required["email"]),
    PassNo: isPassportFieldShow
      ? yup
          .string()
          .required(t.validation_messages.required["passport"])
          .matches(REGEX.Passport, t.validation_messages.valid["passport"])
      : null,
    Govid: isPanFieldShow
      ? yup
          .string()
          .required(t.validation_messages.required["govid"])
          .matches(REGEX.Govid, t.validation_messages.valid["govid"])
      : null,
  });
};

//login form validation
export const loginValidationSchema = (t) => {
  return yup.object({
    mobileCode: yup
      .string()
      //  .min(10, `${t.validation_messages.valid["mobile"]}`)
      //  .max(10, `${t.validation_messages.valid["mobile"]}`)
      .matches(REGEX.mobile, t.validation_messages.valid["mobile"])
      .required(t.validation_messages.required["mobile"]),
  });
};

//unique code form validation
export const uniqueCodeValidationSchema = (t) => {
  return yup.object({
    UniqueCode: yup
      .string()
      .matches(REGEX.Code, t.validation_messages.valid["code"])
      .required(t.validation_messages.required["code"]),
  });
};

//mobile number validation for refer friend
export const mobileNoValidationSchema = (t) => {
  return yup.object({
    mobile: yup
      .string()
      .matches(REGEX.mobile, t.validation_messages.valid["mobile"])
      .required(t.validation_messages.required["mobile"]),
  });
};

//refer code validation
export const referCodeValidationSchema = (t) => {
  return yup.object({
    code: yup.string().required(t.validation_messages.required["refer_code"]),
  });
};

//edit profile validation
export const profileCardSchema = (t) => {
  return yup.object({
    name: yup
      .string()
      .required(t.validation_messages.required["name"])
      .nullable() // Allow undefined or empty string values
      .matches(REGEX.nameWithSpace, t.validation_messages.valid["name"]),
    mobile: yup.string().nullable(), // Allow undefined or empty string values
    email: yup
      .string()
      .required(t.validation_messages.required["email"])
      .nullable() // Allow undefined or empty string values
      .email(t.validation_messages.valid["email"])
      .matches(REGEX.email, t.validation_messages.valid["email"]),
    dob: yup
      .date()
      .nullable() // Allow undefined or empty string values
      .required(t.validation_messages.required["date"])
      .test("is-over-18", t.validation_messages.valid["age"], function (value) {
        // Check if the value is provided before comparing dates
        if (!value) {
          return true; // Allow empty or undefined values
        }
        const currentDate = new Date();
        const minBirthDate = new Date(
          currentDate.getFullYear() - 18,
          currentDate.getMonth(),
          currentDate.getDate()
        );
        return new Date(value) <= minBirthDate;
      }),
    gender: yup.string().required("Gender is required"),
    // alternate_mobile: yup
    //   .string()
    //   .required(t.validation_messages.required["mobile"])
    //   .min(10, `${t.validation_messages.valid["mobile"]}`)
    //   .max(10, `${t.validation_messages.valid["mobile"]}`)
    //   .matches(REGEX.mobile, t.validation_messages.valid["mobile"])
    //   // .nullable(),
    mobileCode: yup
      .string()
      // .required(t.validation_messages.required["mobile"])
      .min(10, `${t.validation_messages.valid["mobile"]}`)
      .max(10, `${t.validation_messages.valid["mobile"]}`)
      .matches(REGEX.mobile, t.validation_messages.valid["mobile"])
      .nullable(),
  });
};

// audio and video validation schema
// export const uploadAudioVideoSchema = (t) => {
//   return yup.object({
//     audioVideoFile: yup
//       .mixed()
//       .required("File is required")
//       .test(
//         "fileValidation",
//         "File format or size is invalid",
//         function (file) {
//           if (!file) return true; // Allow empty file

//           return new Promise((resolve) => {
//             let allowedFormats;
//             let allowedSize;
//             let durationCheck;

//             if (file.type.startsWith('audio')) {
//               allowedFormats = ["audio/mp3", "audio/wav"];
//               allowedSize = 50 * 1024 * 1024; // 50MB for audio files
//               const audio = document.createElement('audio');
//               audio.src = URL.createObjectURL(file);
//               audio.addEventListener('loadedmetadata', () => {
//                 const duration = audio.duration;
//                 durationCheck = duration <= 30;
//                 handleValidation();
//               });
//             } else if (file.type.startsWith('video')) {
//               allowedFormats = ["video/mp4"];
//               allowedSize = 100 * 1024 * 1024; // 100MB for video files
//               const video = document.createElement('video');
//               video.src = URL.createObjectURL(file);
//               video.addEventListener('loadedmetadata', () => {
//                 const duration = video.duration;
//                 durationCheck = duration <= 30;
//                 handleValidation();
//               });
//             } else {
//               resolve(this.createError({
//                 path: this.path,
//                 message: `Invalid file type. Only audio/mp3, audio/wav, and video/mp4 are allowed.`,
//               }));
//             }

//             const handleValidation = () => {
//               const isValidFormat = allowedFormats.includes(file.type);
//               const isValidSize = file.size <= allowedSize;

//               if (!isValidFormat) {
//                 resolve(this.createError({
//                   path: this.path,
//                   message: `Invalid file format. Allowed formats are: ${allowedFormats.join(', ')}.`,
//                 }));
//               } else if (!isValidSize) {
//                 resolve(this.createError({
//                   path: this.path,
//                   message: `File size exceeds the maximum limit of ${allowedSize / (1024 * 1024)} MB. Please upload a smaller file.`,
//                 }));
//               } else if (!durationCheck) {
//                 resolve(this.createError({
//                   path: this.path,
//                   message: `File duration exceeds the maximum limit of 30 seconds.`,
//                 }));
//               } else {
//                 resolve(true);
//               }
//             };
//           });
//         }
//       )
//   });
// };

// only video schema

// export const uploadAudioVideoSchema = (t) => {
//   return yup.object({
//     audioVideoFile: yup
//       .mixed()
//       .required("File is required")
//       .test(
//         "fileValidation",
//         "File format or size is invalid",
//         function (file) {
//           if (!file) return true; // Allow empty file

//           return new Promise((resolve) => {
//             const allowedFormat = "video/mp4";
//             const allowedSize = 30 * 1024 * 1024; // 30MB for video files

//             if (file.type === allowedFormat) {
//               const video = document.createElement('video');
//               video.src = URL.createObjectURL(file);
//               video.addEventListener('loadedmetadata', () => {
//                 const duration = video.duration;

//                 if (typeof duration === 'undefined') {
//                   resolve(this.createError({
//                     path: this.path,
//                     message: "Unable to determine video duration.",
//                   }));
//                 } else if (duration > 30) {
//                   resolve(this.createError({
//                     path: this.path,
//                     message: "Video duration exceeds the maximum limit of 30 seconds."
//                   }));
//                 } else if (typeof file.size === 'undefined') {
//                   resolve(this.createError({
//                     path: this.path,
//                     message: "Unable to determine file size.",
//                   }));
//                 } else if (file.size > allowedSize) {
//                   resolve(this.createError({
//                     path: this.path,
//                     message: `File size exceeds the maximum limit of ${allowedSize / (1024 * 1024)} MB. Please upload a smaller file.`,
//                   }));
//                 } else {
//                   resolve(true);
//                 }
//               });
//             } else {
//               resolve(this.createError({
//                 path: this.path,
//                 message: `Invalid file format. Only ${allowedFormat} is allowed.`,
//               }));
//             }
//           });
//         }
//       )
//   });
// };
// image video audio Schema

export const uploadAudioVideoSchema = (t) => {
  return yup.object({
    audioVideoFile: yup
      .mixed()
      .required("File is required")
      .test(
        "fileValidation",
        "File format or size is invalid",
        function (file) {
          if (!file) return true; // Allow empty file

          return new Promise((resolve) => {
            const allowedVideoFormat = "video/mp4";
            const allowedAudioFormat = "audio/mpeg"; // mp3 format
            const allowedImageFormats = [
              "image/jpeg",
              "image/jpg",
              "image/png",
            ];
            const allowedVideoSize = 100 * 1024 * 1024; // 100MB for video files
            const allowedAudioSize = 100 * 1024 * 1024; // 100MB for audio files
            const allowedImageSize = 10 * 1024 * 1024; // 10MB for image files

            const createError = (message) => {
              resolve(
                this.createError({
                  path: this.path,
                  message: message,
                })
              );
            };

            if (file.type === allowedVideoFormat) {
              const video = document.createElement("video");
              video.src = URL.createObjectURL(file);
              video.addEventListener("loadedmetadata", () => {
                const duration = video.duration;

                if (typeof duration === "undefined") {
                  createError("Unable to determine video duration.");
                } else if (duration < 5) {
                  createError("Video duration must be at least 5 seconds.");
                } else if (duration > 90) {
                  createError("Video duration exceeds the maximum limit of 90 seconds.");
                } else if (typeof file.size === "undefined") {
                  createError("Unable to determine file size.");
                } else if (file.size > allowedVideoSize) {
                  createError(
                    `File size exceeds the maximum limit of ${
                      allowedVideoSize / (1024 * 1024)
                    } MB. Please upload a smaller file.`
                  );
                } else {
                  resolve(true);
                }
              });
            } else if (file.type === allowedAudioFormat) {
              const audio = document.createElement("audio");
              audio.src = URL.createObjectURL(file);
              audio.addEventListener("loadedmetadata", () => {
                const duration = audio.duration;

                if (typeof duration === "undefined") {
                  createError("Unable to determine audio duration.");
                } else if (duration < 5) {
                  createError("Audio duration must be at least 5 seconds.");
                } else if (duration > 90) {
                  createError("Audio duration exceeds the maximum limit of 90 seconds.");
                } else if (typeof file.size === "undefined") {
                  createError("Unable to determine file size.");
                } else if (file.size > allowedAudioSize) {
                  createError(
                    `File size exceeds the maximum limit of ${
                      allowedAudioSize / (1024 * 1024)
                    } MB. Please upload a smaller file.`
                  );
                } else {
                  resolve(true);
                }
              });
            } else if (allowedImageFormats.includes(file.type)) {
              if (typeof file.size === "undefined") {
                createError("Unable to determine file size.");
              } else if (file.size > allowedImageSize) {
                createError(
                  `File size exceeds the maximum limit of ${
                    allowedImageSize / (1024 * 1024)
                  } MB. Please upload a smaller file.`
                );
              } else {
                resolve(true);
              }
            } else {
              createError(
                `Invalid file format. Only ${allowedVideoFormat}, ${allowedAudioFormat}, and ${allowedImageFormats.join(
                  ", "
                )} are allowed.`
              );
            }
          });
        }
      ),
  });
};