import React, { useState, useEffect, useMemo } from 'react';

const Timer = ({ title, targetTime }) => {
  const calculateTimeLeft = useMemo(() => {
    return () => {
      const now = new Date().getTime();
      const difference = targetTime - now;

      if (difference > 0) {
        const hours = Math.floor(
          (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((difference % (1000 * 60)) / 1000);

        return { hours, minutes, seconds };
      } else {
        return { hours: 0, minutes: 0, seconds: 0 };
      }
    };
  }, [targetTime]);

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timerInterval = setInterval(() => {
      setTimeLeft(prev => {
        const newTimeLeft = calculateTimeLeft();
        if (
          newTimeLeft.hours !== prev.hours ||
          newTimeLeft.minutes !== prev.minutes ||
          newTimeLeft.seconds !== prev.seconds
        ) {
          return newTimeLeft;
        }
        return prev;
      });
    }, 1000);

    return () => clearInterval(timerInterval);
  }, [calculateTimeLeft]);

  const formatTime = (time) => (
    <p><span>{`${title}:`}</span> <span className='text-danger'>{`${time.hours}:${time.minutes}:${time.seconds}`}</span></p>
  );

  return formatTime(timeLeft);
};

export default Timer;
