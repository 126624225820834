export const referalSuccessContentType = (t, referralCode) => {
  console.log(referralCode, "referralCode");
  return {
    image: "/assets/img/modal/right-icon.svg",
    heading: t.model_messages.thank_you,
    subHeading: t.model_messages.an_invite_sent,
    referralCode: referralCode ? referralCode : "",
    timer: false,
    description: t.model_messages.your_referral_point_credited,
    id: 15,
  };
};

export const signupSuccessContentType = (t, onAction = () => { }) => {
  return {
    image: "/assets/img/modal/surprise-icon.svg",
    heading: t.model_messages.surprise,
    subHeadingPart1: t.model_messages.you_earned_for_success_signingup,
    timer: false,
    cta: t.model_messages.okay,
    id: 1,
    onAction: onAction
  }
};


export const referralSignupBonus300 = (t, onAction = () => { }) => {
  return {
    image: "/assets/img/modal/levelled_up.svg",
    heading: t.model_messages.congratulations,
    subHeadingPart1:
      t.model_messages.you_earned_for_success_signingup_via_referral,
    timer: false,
    cta: t.model_messages.okay,
    id: 18,
    onAction: onAction
  };
};


export const referralAcceptedCoins100 = (t, onAction = () => { }) => {
  return {
    image: "/assets/img/modal/levelled_up.svg",
    heading: t.model_messages.congratulations,
    subHeadingPart1:
      t.model_messages.you_have_earned_100_coke_coins_for_a_successful_referral,
    timer: false,
    cta: t.model_messages.okay,
    id: 18,
    onAction: onAction
  };
};

export const profileCompletionPopup = (t) => {
  return {
    image: "/assets/img/modal/levelled_up.svg",
    heading: t.model_messages.congratulations,
    subHeadingPart1: t.model_messages.completing_your_profile,
    timer: false,
    cta: t.model_messages.okay,
    id: 4,
  }
};


export const contentSuccessullyPostedPopup = (t) => {
  return {
    image: "/assets/img/modal/levelled_up.svg",
    heading: t.model_messages.spotlight_heading,
    subHeadingPart1: t.model_messages.content_has_successfully_posted,
    timer: false,
    cta: t.model_messages.okay,
    id: 17,
  }
};
export const contentRejectedPopup = (t) => {
  return {
    image: "/assets/img/modal/rejection-icon.svg",
    heading: t.model_messages.ohh_no,
    subHeadingPart1: t.model_messages.your_content_rejected,
    timer: false,
    cta: t.model_messages.okay,
    id: 16,
  }
}


export const earnedCoinOnLikePopup = (t) => {
  return {
    image: "/assets/img/modal/fantastic.svg",
    heading: t.model_messages.fantastic_heading,
    subHeadingPart1: t.model_messages.you_have_earned_for_liking_content,
    timer: false,
    cta: t.model_messages.okay,
    id: 19,
  }
}

export const earnedCoinOnSharePopup = (t) => {
  return {
    image: "/assets/img/modal/social-star.svg",
    heading: t.model_messages.social_star_heading,
    subHeadingPart1: t.model_messages.you_have_earned_for_sharing_content,
    timer: false,
    cta: t.model_messages.okay,
    id: 20,
  }
}

export const reachedLimitOnLikeEarnPopup = (t) => {
  return {
    image: "/assets/img/modal/daily-limit.svg",
    heading: t.model_messages.sorry,
    subHeadingPart1: t.model_messages.you_have_reached_weekly_limit_for_like,
    subHeadingPart2: t.model_messages.keep_liking,
    description: t.model_messages.try_again_next_week,
    timer: false,
    cta: false,
    id: 22,
  }
}


export const reachedLimitOnShareEarnPopup = (t) => {
  return {
    image: "/assets/img/modal/daily-limit.svg",
    heading: t.model_messages.sorry,
    subHeadingPart1: t.model_messages.you_have_reached_weekly_limit_for_share,
    subHeadingPart2: t.model_messages.keep_sharing,
    description: t.model_messages.try_again_next_week,
    timer: false,
    cta: false,
    id: 23,
  }
}

export const weeklyLoginRewardEarnPopup = (t) => {
  return {
    image: "/assets/img/modal/levelled_up.svg",
    heading: t.model_messages.just_beat_it,
    subHeadingPart1: t.model_messages.completing_the_login_progress,
    timer: false,
    cta: t.model_messages.okay,
    id: 16,
  }
}